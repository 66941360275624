import { ContributorButtonsBar, OwnerButtonsBar } from './ButtonsBar'
import { ContributionInput } from './ContributionInput'
import { Article, Contribution } from '../types'
import {
  ReserveContributionInput,
  useReserveContributionMutation,
} from '../../../generated/graphql'
import { MessageType } from '../../../utils/message-type'
import Toast from 'react-native-root-toast'
import * as Sentry from '@sentry/react-native'
import { ContributionType } from '../../../generated/graphql'
import { DefaultBar } from './DefaultBar'
import { ArticleEditorRef } from '../../../components/ArticleEditor/ArticleEditor'
import { useTranslation } from 'react-i18next'
import { ContributionDetails } from './ContributionDetails'

const ContributionsButtonsBar = ({
  perspective,
  selection,
  editorRef,
  setReservedContribution,
}: {
  perspective: Article
  selection: { start: number; end: number }
  editorRef: React.RefObject<ArticleEditorRef>
  setReservedContribution: (contribution: Contribution | null) => void
}) => {
  const [doReserveContribution] = useReserveContributionMutation()
  const { t } = useTranslation('perspective')

  const createContribution = async (type: ContributionType) => {
    const { start, end } = selection
    if (end - start < 1 || !perspective) {
      Toast.show(t('wrong-selection'))
      return
    }
    editorRef.current?.lockSelection(true)
    const input: ReserveContributionInput = {
      articleId: perspective.id,
      begin: start,
      end,
      clusterId: perspective.clusterId,
      basedOnVersion: perspective.version,
      intendedType: type,
    }
    try {
      const result = await doReserveContribution({ variables: { input } })
      setReservedContribution(result.data!.reserveContribution || null)
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
      editorRef.current?.lockSelection(false)
      console.error(e)
      Sentry.captureException(e)
    }
  }

  if (!perspective) {
    return null
  }
  if (perspective.isOwner) {
    return <OwnerButtonsBar onClick={createContribution} />
  }
  return <ContributorButtonsBar onClick={createContribution} />
}

export const BottomBar = ({
  perspective,
  selection,
  editorRef,
  reservedContribution,
  editorContributionId,
  onReservedContribution,
  selectContributionChange,
}: {
  perspective: Article
  reservedContribution: Contribution | null
  selection: { start: number; end: number }
  editorContributionId: string
  editorRef: React.RefObject<ArticleEditorRef>
  onReservedContribution: (contribution: Contribution | null) => void
  selectContributionChange: (change: 1 | -1 | null) => void
}) => {
  /**
   * We are writing a new contribution
   */
  if (reservedContribution) {
    return (
      <ContributionInput
        context={perspective?.latestContent || ''}
        contribution={reservedContribution}
        inputHandler={() => {
          // After submit we clear the selection
          onReservedContribution(null)
        }}
      />
    )
  }

  /**
   * We have selected some text and we can add a contribution
   */
  if (selection.end - selection.start > 0) {
    return (
      <ContributionsButtonsBar
        perspective={perspective}
        selection={selection}
        editorRef={editorRef}
        setReservedContribution={onReservedContribution}
      />
    )
  }

  /**
   * We are looking at a contribution and can navigate through them
   */
  if (editorContributionId) {
    return (
      <ContributionDetails
        perspective={perspective as Article}
        contributionId={editorContributionId || null}
        onClose={() => selectContributionChange(null)}
        onNext={() => selectContributionChange(1)}
        onPrevious={() => selectContributionChange(-1)}
      />
    )
  }

  /**
   * Default Bar
   */
  return (
    <DefaultBar
      articleId={perspective?.id}
      owner={perspective?.owner || { id: '', username: '' }}
    />
  )
}
