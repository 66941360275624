/**
 * Creates a debounce function that debounces the callback by the given delay.
 * @param delay
 * @returns
 */
export const createDebounce = (delay: number = 500) => {
  let timeoutId: NodeJS.Timeout

  return (callback: Function) => {
    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    // Set new timeout
    timeoutId = setTimeout(() => {
      callback()
    }, delay)
  }
}
export const parseHtmlContent = (htmlContent: string): string => {
  if (!htmlContent) return ''

  return (
    htmlContent

      // Replace Strong Tags
      .replace(/<strong>(.*?)<\/strong>/gi, '$1')
      // Replace Italic Tags
      .replace(/<i>(.*?)<\/i>/gi, '$1')
      // Replace Emphasized Tags
      .replace(/<em>(.*?)<\/em>/gi, '$1')
      // Replace Underlined Tags
      .replace(/<u>(.*?)<\/u>/gi, '$1')
      // Replace A Tags
      .replace(/<a[^>]*>(.*?)<\/a>/gi, '$1')
      // Replace H1 Tags
      .replace(/<h[1-6]>/gi, '')
      .replace(/<\/h[1-6]>/gi, '\n\n')
      // Replace <br> and <br/> with newlines
      .replace(/<br\s*\/?>/gi, '\n')
      // Replace paragraph tags with newlines
      .replace(/<p>/gi, '')
      .replace(/<\/p>/gi, '\n')
      // Remove <ul> tags
      .replace(/<\/?ul>/gi, '')
      // Replace <li> tags with dashes
      .replace(/<li>/gi, '- ')
      .replace(/<\/li>/gi, '\n')
      // Remove any other HTML tags
      .replace(/<[^>]*>/g, '')
      // Remove multiple consecutive newlines
      // .replace(/\n\s*\n/g, '\n')
      // Remove multiple consecutive spaces
      .replace(/\s\s+/g, ' ')
      // Trim extra whitespace
      .trim()
      // Replace &; Tags
      .replace(/&amp;/gi, '&')
      .replace(/&nbsp;/gi, ' ')
      .replace(/&quot;/gi, '"')
      .replace(/&apos;/gi, "'")
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')
  )
}
