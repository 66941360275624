import * as React from 'react'
import { CSSProperties, Ref, useEffect, useImperativeHandle } from 'react'
import { StyleProp, StyleSheet } from 'react-native'

interface WebViewWebProps {
  title: string
  src: string
  onLoad: () => void
  onMessage: (event: any) => void
  style?: StyleProp<CSSProperties>
  injectedJavaScript?: string
}

const styles = StyleSheet.create({
  iframe: {
    width: '100%',
    minHeight: '100%',
    borderWidth: 0,
  },
})

export interface WebViewWebRef {
  postMessage: (message: string) => void
  injectJavaScript: (script: string) => void
}

function WebViewWebImpl(props: WebViewWebProps, ref: Ref<WebViewWebRef>) {
  const iframe = React.useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    const handler = (nativeEvent: any) => {
      props.onMessage({ nativeEvent })
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  })

  const { onLoad, style } = props
  useImperativeHandle(ref, () => {
    return {
      postMessage: (message: string) => {
        iframe.current?.contentWindow?.postMessage(message, '*')
      },
      injectJavaScript: (script: string) => {
        // @ts-ignore
        iframe.current?.contentWindow.eval?.(script)
      },
    }
  })

  return (
    <iframe
      id={'iframe'}
      ref={iframe}
      src={props.src}
      style={StyleSheet.flatten([styles.iframe, style])}
      onLoad={onLoad}
      onError={(e) => {
        console.error('Error', e)
      }}
    ></iframe>
  )
}

export const WebViewWeb = React.forwardRef<WebViewWebRef, WebViewWebProps>(
  WebViewWebImpl
)
