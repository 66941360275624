import * as React from 'react'
import { useEffect } from 'react'
import { FlatList, RefreshControl, StyleSheet, Text, View } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { NodeListItem } from './components/NodeListItem'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { useTranslation } from 'react-i18next'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import Row from '../../components/containers/Row'
import { useAvailableNodesQuery } from '../../generated/graphql'
import { MelddSearchBar } from '../../components/form/MelddSearchBar'
import { IconFilter } from '../../utils/meldd-icons'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  welcome: {
    padding: 20,
    fontSize: 20,
    textAlign: 'center',
  },
})

export function ListNodesScreen({
  navigation,
}: StackScreenProps<AppNavigatorParams, 'ListNodes'>) {
  const isFocused = useIsFocused()
  const { t } = useTranslation('nodes')
  const [searchQuery, setSearchQuery] = React.useState('')
  const globalStyles = useGlobalStyles()
  const { data, refetch, loading } = useAvailableNodesQuery({
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])

  if (loading) {
    return (
      <View style={styles.container}>
        <Text>{t('Loading')}</Text>
      </View>
    )
  }

  const filteredNodes =
    data?.availableNodes.filter((node) =>
      node.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []

  return (
    <View style={[globalStyles.pageContainer]}>
      <Row
        justifyContent="space-between"
        wrap={true}
        style={styles.welcome}
        columnGap={4}
      >
        <Text>{t('welcome')}</Text>
        <View style={{ flex: 1, minWidth: 200 }}>
          <MelddSearchBar
            icon={IconFilter}
            placeholder={t('search-nodes')}
            onChangeText={setSearchQuery}
            value={searchQuery}
          />
        </View>
      </Row>
      {filteredNodes.length ? (
        <ScreenWrapper
          contentContainerStyle={{ height: '100%' }}
          refreshControl={
            <RefreshControl refreshing={loading} onRefresh={refetch} />
          }
        >
          <FlatList
            data={filteredNodes}
            renderItem={({ item }) => (
              <NodeListItem
                node={item}
                onNavigate={() => {
                  navigation.navigate('ClusterList', {
                    nodeId: item.id,
                  })
                }}
              />
            )}
          />
        </ScreenWrapper>
      ) : (
        <View style={styles.container}>
          <Text>{t('No Nodes')}</Text>
        </View>
      )}
    </View>
  )
}
export default ListNodesScreen
