import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Button, Text, TextInput } from 'react-native-paper'
import Checkbox from 'expo-checkbox'
import { useMemo, useState } from 'react'
import Toast from 'react-native-root-toast'
import {
  ClusterDetailFragmentDoc,
  CreateClusterInput,
  useCreateClusterMutation,
} from '../../generated/graphql'
import { StackScreenProps } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { AppNavigatorParams } from '../../navigation/types'
import { MessageType } from '../../utils/message-type'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { LabeledSwitch } from '../../components/LabeledSwitch'
import { useTheme } from '../../hooks/use-theme'
import colors from '../../constants/colors'
import { LLMList } from '../CreateArticle/LLMList'
import * as Sentry from '@sentry/react-native'
import { DEFAULT_MODEL } from '../CreateArticle/LLMProvider'
export function CreateClusterScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'CreateCluster'>) {
  const { nodeId } = route.params
  const [topic, setTopic] = useState<string>('')
  const { t } = useTranslation('clusters')
  const theme = useTheme()
  const [isPrivate, setIsPrivate] = useState<boolean>(false)
  const [showModerators, setShowModerators] = useState<boolean>(false)
  const [showPerspectiveOwners, setShowPerspectiveOwners] =
    useState<boolean>(false)
  const [createPerspectiveWithAi, setCreatePerspectiveWithAI] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [llmModels, setLlmModels] = useState<string[]>([DEFAULT_MODEL])
  const [doCreateCluster, { loading }] = useCreateClusterMutation({
    update: (cache, result) => {
      const { data } = result
      if (data) {
        const field = `availableClusters(${JSON.stringify({ nodeId })})`
        cache.modify({
          fields: {
            [field](existingClusterRefs = []) {
              const newClusterRef = cache.writeFragment({
                data: data.createCluster,
                fragment: ClusterDetailFragmentDoc,
              })
              return [...existingClusterRefs, newClusterRef]
            },
          },
        })
      }
    },
  })

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'column',
          padding: 20,
          alignItems: 'stretch',
          rowGap: theme.spacing(4),
        },
        containerButtons: {
          marginTop: 20,
          //height: 350,
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        },
      }),
    [theme]
  )
  const handleCreateCluster = async () => {
    const input: CreateClusterInput = {
      topic,
      isPrivate,
      showModerators,
      showPerspectiveOwners,
      nodeId,
    }
    try {
      setIsSubmitting(true)
      const result = await doCreateCluster({ variables: { input } })

      if (createPerspectiveWithAi) {
        if (llmModels.length === 0) {
          return Toast.show(t('no-model-selected'), MessageType.error)
        }
        return navigation.replace('CreateArticle', {
          clusterId: result.data?.createCluster.id || '',
          model: llmModels[0],
        })
      }

      result.data ? navigation.goBack() : navigation.pop()
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
      console.error(e)
      Sentry.captureException(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <ScreenWrapper contentContainerStyle={styles.container}>
      <TextInput
        label={t('Cluster Topic')}
        onChangeText={(text) => setTopic(text)}
        value={topic}
        clearButtonMode={'while-editing'}
        mode={'outlined'}
      />
      <LabeledSwitch
        style={{ alignSelf: 'flex-end' }}
        wrapperStyle={{ alignSelf: 'flex-end', columnGap: theme.spacing(4) }}
        label={t('Is Private?')}
        value={isPrivate}
        onValueChange={setIsPrivate}
      />
      <LabeledSwitch
        style={{ alignSelf: 'flex-end' }}
        wrapperStyle={{ alignSelf: 'flex-end', columnGap: theme.spacing(4) }}
        label={t('Show Moderators')}
        value={showModerators}
        onValueChange={setShowModerators}
      />
      <LabeledSwitch
        style={{ alignSelf: 'flex-end' }}
        wrapperStyle={{ alignSelf: 'flex-end', columnGap: theme.spacing(4) }}
        label={t('Show Perspective Owners')}
        value={showPerspectiveOwners}
        onValueChange={setShowPerspectiveOwners}
      />
      <LabeledSwitch
        style={{ alignSelf: 'flex-end' }}
        wrapperStyle={{ alignSelf: 'flex-end', columnGap: theme.spacing(4) }}
        label={t('create-perspective-with-ai')}
        value={createPerspectiveWithAi}
        onValueChange={setCreatePerspectiveWithAI}
      />
      {createPerspectiveWithAi && (
        <LLMList setLlmModels={setLlmModels} llmModels={llmModels} />
      )}
      <View style={styles.containerButtons}>
        {isSubmitting && <ActivityIndicator size={24} color={colors.primary} />}
      </View>
      <View style={styles.containerButtons}>
        <Button onPress={() => navigation.pop()} disabled={loading}>
          {t('Cancel')}
        </Button>
        <Button
          onPress={handleCreateCluster}
          mode="contained"
          disabled={loading || !topic || isSubmitting}
        >
          {t('Create')}
        </Button>
      </View>
    </ScreenWrapper>
  )
}

export default CreateClusterScreen
