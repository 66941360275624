import * as Sentry from '@sentry/react-native'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { IconButton, Text, useTheme } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { AuthContext } from '../auth/auth-context'
import { LabeledItem } from '../components/LabeledItem'
import { LabeledSwitch } from '../components/LabeledSwitch'
import { useFeatures } from '../contexts/FeatureProvider'
import { Env } from '../env'
import { useUserQuery } from '../generated/graphql'
import { useNotifications } from '../hooks/use-notifications'
import { useSocketStatus } from '../socket/socket.hooks'
import { getBrowserInfo } from '../utils/browser'
import { MessageType } from '../utils/message-type'
import { Theme } from '../utils/theme'
import { TutorialStorage } from '../components/Tutorial/TutorialStorage'
import { ScrollView } from 'react-native-gesture-handler'
import { IconCopy } from '../utils/meldd-icons'
import * as Clipboard from 'expo-clipboard'

const VersionInfo = () => {
  const versionInfo = `${Env.VERSION} ${Env.BRANCH_NAME || ''}`

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(versionInfo)
  }

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text>{versionInfo}</Text>
      <IconButton icon={IconCopy} size={20} onPress={copyToClipboard} />
    </View>
  )
}
export function FeatureTogglesScreen() {
  const {
    setShowEditorLogs,
    showEditorLogs,
    developerMode,
    setDeveloperMode,
    setOwnerBumps,
    ownerBumps,
    notifications,
    setNotifications,
    notificationsSound,
    setNotificationsSound,
  } = useFeatures()
  const { t } = useTranslation('menuItems')
  const status = useSocketStatus()
  const theme = useTheme()
  const { id, isAnonymous, notificationsDeviceInfo, updateUserData } =
    useContext(AuthContext)
  const {
    permission,
    requestPermission,
    enableNotifications,
    disableNotifications,
    isSupported,
  } = useNotifications()
  const { data } = useUserQuery({
    variables: { userId: id },
    skip: !id,
  })
  /**
   * Abel July 2024
   * I hide some dev options, to enable then we do like on android, tab 5 times specific label :D
   * It can be improved and keep it in memory, we have the `dev` mode but is open to anyone and crashed in web.
   * I keep this like that for quick short cut.
   */
  const [secretCount, setSecretCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!data?.user || !isSupported) return
    updateUserData(data.user)
    setNotifications(
      data.user.isPushNotificationsEnabled && permission === 'granted'
    )
  }, [data])

  return (
    <ScrollView>
      <View style={styles.container}>
        {Env.IS_DEVELOP && (
          <View style={styles.section}>
            <Text variant={'titleMedium'}>{t('development')}</Text>
            <View style={styles.row}>
              <LabeledSwitch
                value={developerMode}
                label="Developer Mode"
                labelStyle={{ color: 'black', flex: 1 }}
                onValueChange={setDeveloperMode}
              />
            </View>
            <View style={styles.row}>
              <LabeledSwitch
                value={showEditorLogs}
                label="Show Editor Logs"
                labelStyle={{ color: 'black', flex: 1 }}
                onValueChange={setShowEditorLogs}
              />
            </View>
            <View style={styles.row}>
              <LabeledSwitch
                value={ownerBumps}
                label="Allow Owner Bumps"
                labelStyle={{ color: 'black', flex: 1 }}
                onValueChange={setOwnerBumps}
              />
            </View>
          </View>
        )}

        {!isAnonymous && isSupported && (
          <View style={styles.section}>
            <Text variant={'titleMedium'}>{t('notifications')}</Text>
            <View style={styles.row}>
              <LabeledSwitch
                value={notifications}
                label={t('allow-notifications')}
                labelStyle={{ color: 'black', flex: 1 }}
                isLoading={isLoading}
                onValueChange={async () => {
                  if (permission !== 'granted') {
                    await requestPermission()
                    return
                  }
                  setIsLoading(true)
                  !notifications
                    ? await enableNotifications()
                    : await disableNotifications()
                  setIsLoading(false)
                }}
              />
            </View>
            {notifications && (
              <>
                <View style={styles.row}>
                  <LabeledSwitch
                    value={notificationsSound}
                    label={t('allow-notifications-sound')}
                    labelStyle={{ color: 'black', flex: 1 }}
                    onValueChange={setNotificationsSound}
                  />
                </View>
                <View style={styles.row}>
                  <LabeledItem
                    label={t('receive-notifications')}
                    labelStyle={{ color: 'black', flex: 1 }}
                  >
                    <Text>{notificationsDeviceInfo || getBrowserInfo()}</Text>
                  </LabeledItem>
                </View>
              </>
            )}
          </View>
        )}

        <View style={styles.section}>
          <Text variant={'titleMedium'}>{t('network')}</Text>
          <View style={styles.row}>
            <LabeledItem
              label={t('Status')}
              labelStyle={{ color: 'black', flex: 1 }}
              onLabelPress={() => setSecretCount((count) => count + 1)}
            >
              <Text
                style={{ color: status === 'connected' ? undefined : '#aaa' }}
              >
                {status === 'connected' ? t('Online') : t('Offline')}{' '}
              </Text>
            </LabeledItem>
          </View>
        </View>

        <View style={styles.section}>
          <Text variant={'titleMedium'}>{t('AboutUs')}</Text>
          <View style={styles.row}>
            <LabeledItem
              label={t('Version')}
              labelStyle={{ color: 'black', flex: 1 }}
              onLabelPress={() => setSecretCount((count) => count + 1)}
            >
              <VersionInfo />
            </LabeledItem>
          </View>
        </View>

        {secretCount > 4 && (
          <>
            <View style={styles.section}>
              <Text variant={'titleMedium'}>{t('Develop')}:</Text>
              <View style={styles.row}>
                <LabeledItem
                  label={'Test Sentry'}
                  labelStyle={{ color: 'black', flex: 1 }}
                >
                  <IconButton
                    style={{ margin: 0 }}
                    size={20}
                    icon={'bug'}
                    onPress={() => {
                      if (!Env.SENTRY_SDN) {
                        Toast.show(
                          'Sentry not enabled (develop mode is disable by default)',
                          MessageType.warn
                        )
                        return
                      }
                      Sentry.captureException(new Error('First error'))
                      Toast.show('Error sent', MessageType.info)
                    }}
                  />
                </LabeledItem>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.row}>
                <LabeledItem
                  label={'Reset Tutorial'}
                  labelStyle={{ color: 'black', flex: 1 }}
                >
                  <IconButton
                    style={{ margin: 0 }}
                    size={20}
                    icon={'delete'}
                    onPress={async () => {
                      try {
                        await TutorialStorage.clearTutorialStorage()
                        Toast.show('Tutorial already reseted', {
                          position: Toast.positions.BOTTOM,
                        })
                      } catch (error) {
                        Toast.show('Failed to reset Tutorial', {
                          position: Toast.positions.BOTTOM,
                        })
                      }
                    }}
                  />
                </LabeledItem>
              </View>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  section: {
    paddingTop: 20,
    paddingHorizontal: 16,
  },
  row: {
    paddingHorizontal: 12,
    paddingVertical: 20,
    borderColor: Theme.color.separator,
    borderBottomWidth: 1,
    alignItems: 'stretch',
  },
})
export default FeatureTogglesScreen
