import { useCallback, useRef, useState } from 'react'
import {
  View,
  StyleSheet,
  Modal,
  TouchableWithoutFeedback,
  Dimensions,
  Platform,
} from 'react-native'
import { Button, Text } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useTheme } from '../../hooks/use-theme'
import { TutorialElement, useTutorial } from './TutorialContext'
import * as Sentry from '@sentry/react-native'
import { useFocusEffect } from '@react-navigation/native'
import { TutorialKey, TutorialStorage } from './TutorialStorage'

export interface TutorialStep {
  tutorialId: string
  message: string
}

interface TutorialProps {
  steps: TutorialStep[]
  tutorialKey: TutorialKey
}

const Mask = ({
  targetLayout,
  onMaskPress,
}: {
  targetLayout: TutorialElement
  onMaskPress: () => void
}) => {
  const MASK_PADDING = 5
  const COLOR = 'rgba(0, 0, 0, 0.7)'
  return (
    <View style={{ flex: 1 }}>
      {/* Top mask */}
      <View
        style={{
          height: targetLayout.y - MASK_PADDING,
          backgroundColor: COLOR,
        }}
      />
      {/* Middle section with hole */}
      <View
        style={{
          flexDirection: 'row',
          height: targetLayout.height + MASK_PADDING * 2,
        }}
      >
        {/* Left mask */}
        <View
          style={{
            width: targetLayout.x - MASK_PADDING,
            backgroundColor: COLOR,
          }}
        />
        {/* Transparent hole */}
        <TouchableWithoutFeedback onPress={onMaskPress}>
          <View
            style={{
              width: targetLayout.width + MASK_PADDING * 2,
            }}
          />
        </TouchableWithoutFeedback>
        {/* Right mask */}
        <View style={{ flex: 1, backgroundColor: COLOR }} />
      </View>
      {/* Bottom mask */}
      <View style={{ flex: 1, backgroundColor: COLOR }} />
    </View>
  )
}
export function Tutorial({ steps, tutorialKey }: TutorialProps) {
  const [showTutorial, setShowTutorial] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [targetLayout, setTargetLayout] = useState<TutorialElement | null>(null)
  const { t } = useTranslation('tutorials')
  const theme = useTheme()
  const windowDimensions = Dimensions.get('window')
  const { findElement, getElement } = useTutorial()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleComplete = useCallback(async () => {
    try {
      TutorialStorage.setShown(tutorialKey)
      findElement('') // Unselect latest active element
      setShowTutorial(false)
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error saving tutorial status:', error)
    }
  }, [tutorialKey, findElement, setShowTutorial])

  const handleNext = useCallback(
    async (step: number) => {
      if (step >= steps.length) {
        handleComplete()
        return
      }

      // Find all views in the app
      const elementId = steps[step].tutorialId
      const element =
        step > 0 ? getElement(elementId) : await findElement(elementId)

      if (!element) {
        setShowTutorial(false)
        console.warn(
          `Tutorial element ${steps[currentStep].tutorialId} not found`
        )
        handleNext(step + 1)
        return
      }
      setCurrentStep(step)

      setTargetLayout(element as TutorialElement)
    },
    [
      steps,
      handleComplete,
      getElement,
      findElement,
      setCurrentStep,
      setTargetLayout,
    ]
  )
  const checkTutorialStatus = useCallback(async () => {
    try {
      const isDisplayed = await TutorialStorage.isAlreadyDisplayed(tutorialKey)
      if (isDisplayed) {
        return
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
      setShowTutorial(true)
      timeoutRef.current = setTimeout(() => {
        handleNext(0)
      }, 500)
    } catch (error) {
      console.error('Error checking tutorial status:', error)
    }
  }, [tutorialKey, handleNext])

  useFocusEffect(
    useCallback(() => {
      checkTutorialStatus()
    }, [checkTutorialStatus])
  )

  useFocusEffect(
    useCallback(() => {
      return () => {
        setShowTutorial(false)
        findElement('')
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }
      }
    }, [tutorialKey])
  )
  if (!targetLayout) return null

  const modalPosition = {
    top: targetLayout.y + targetLayout.height + 10,
    left: targetLayout.x,
  }

  // Adjust position if it goes off screen
  if (modalPosition.top + 100 > windowDimensions.height) {
    modalPosition.top = targetLayout.y - 100
  }
  if (modalPosition.left + 300 > windowDimensions.width) {
    modalPosition.left = windowDimensions.width - 310
  }
  if (modalPosition.top < 20) {
    modalPosition.top = 20
  }
  if (modalPosition.left < 20) {
    modalPosition.left = 20
  }

  const onClose = () => {
    setShowTutorial(false)
  }

  return (
    <Modal transparent visible={showTutorial} animationType="fade">
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.overlay}>
          <Mask
            targetLayout={{ ...targetLayout, x: targetLayout.x }}
            onMaskPress={() => handleNext(currentStep + 1)}
          />
          <TouchableWithoutFeedback>
            <View
              style={[
                styles.messageContainer,
                {
                  top: modalPosition.top,
                  left: modalPosition.left,
                  backgroundColor: theme.colors.surface,
                },
              ]}
            >
              <Text style={{ color: theme.colors.onSurface }}>
                {steps[currentStep].message}
              </Text>
              <View style={styles.buttonContainer}>
                <Button
                  mode="contained"
                  compact
                  style={{ paddingHorizontal: 8 }}
                  onPress={() => handleNext(currentStep + 1)}
                >
                  {currentStep < steps.length - 1 ? t('next') : t('finish')}
                </Button>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
  },
  messageContainer: {
    position: 'absolute',
    padding: 24,
    borderRadius: 8,
    width: 300,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      },
      android: {
        elevation: 5,
      },
    }),
  },
  buttonContainer: {
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
