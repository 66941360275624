import { IconCluster, IconPerspective } from '../../utils/meldd-icons'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../navigation/types'
import { MelddListItem } from '../../components/containers/MelddListItem'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'react-native-paper'
import { HighlightText } from './highlight-text.component'
import { SearchResult } from '../../generated/graphql'
import { Pressable, View } from 'react-native'
import Row from '../../components/containers/Row'
import { useMemo } from 'react'
import { parseHtmlContent } from '../../utils'
import { useIsMobileDevice } from '../../hooks/useIsMobile'
export const SearchItem = ({
  item,
  searchTerm,
}: {
  item: SearchResult
  searchTerm: string
}) => {
  const navigation = useNavigation<AppNavigation>()
  const { t } = useTranslation(['search'])
  const theme = useTheme()
  const isMobile = useIsMobileDevice()

  const latestContentText = useMemo(() => {
    if (item.article_content) {
      return parseHtmlContent(item.article_content)
    }
    return ''
  }, [item.article_content])

  const Title = useMemo(() => {
    return (
      <Row>
        <IconPerspective
          size={12}
          color={theme.colors.onSurface}
          style={{ marginRight: 4 }}
        />
        <HighlightText
          variant="titleMedium"
          text={item.article_title}
          searchTerm={searchTerm}
        />
      </Row>
    )
  }, [item.article_title, searchTerm])

  const ClusterTitle = useMemo(() => {
    return (
      <Pressable
        onPress={() => {
          navigation.navigate('ClusterCards', {
            clusterId: item.cluster_id,
            nodeId: item.node_id,
          })
        }}
      >
        <Row>
          <View style={{ marginRight: 4 }}>
            <IconCluster size={18} color={theme.colors.onSurface} />
          </View>
          <HighlightText
            variant="titleSmall"
            text={item.cluster_topic}
            searchTerm={searchTerm}
          />
        </Row>
      </Pressable>
    )
  }, [item.cluster_topic])
  return (
    <MelddListItem
      onPress={() => {
        navigation.navigate('Perspective', {
          articleId: item.article_id,
        })
      }}
      title={Title}
      description={
        <View style={{ padding: 4, width: '100%' }}>
          <HighlightText
            variant="bodySmall"
            text={(() => {
              const matchIndex = latestContentText
                ?.toLowerCase()
                .indexOf(searchTerm.toLowerCase())

              // Get the first 100 characters of the article content
              if (matchIndex === -1) return latestContentText?.substring(0, 300)

              // Get the lines around the match
              return latestContentText?.substring(
                Math.max(0, matchIndex - 150),
                Math.min(latestContentText.length, matchIndex + 150)
              )
            })()}
            searchTerm={searchTerm}
          />

          {isMobile ? (
            <Row style={{ marginTop: 4 }} flex={1} justifyContent="flex-end">
              {ClusterTitle}
            </Row>
          ) : null}
        </View>
      }
      right={() => (isMobile ? null : ClusterTitle)}
    />
  )
}
