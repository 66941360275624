import { Icon3dVoteCount, IconGoTo } from '../../utils/meldd-icons'
import Animated from 'react-native-reanimated'

import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../navigation/types'
import { Cluster } from './types'
import { ContributionStatus, ContributionType } from '../../generated/graphql'
import { Pressable, useWindowDimensions } from 'react-native'
import { View } from 'react-native'
import { Icon3dContributions, Icon3dPerspective } from '../../utils/meldd-icons'
import { IconButton, Text } from 'react-native-paper'
import { createStyle } from '../../contexts/GlobalStylesProvider'

interface ClusterListItemProps {
  cluster: Cluster
}
export const ClusterCard = ({ cluster }: ClusterListItemProps) => {
  const navigation = useNavigation<AppNavigation>()
  const { width } = useWindowDimensions()

  const styles = useStyle()
  const onPress = () =>
    navigation.push('ClusterCards', {
      clusterId: cluster.id,
      nodeId: cluster.nodeId,
    })

  const voteCount = cluster.articleVotes.reduce((acc, vote) => acc + 1, 0)
  const contributionCount = cluster.articles.reduce(
    (count, article) =>
      count +
      article.contributions.filter(
        (c) =>
          c.contributionType != ContributionType.Reserved &&
          c.status != ContributionStatus.Censured &&
          c.status != ContributionStatus.Cancelled
      ).length,
    0
  )

  return (
    <Animated.View>
      <Pressable onPress={onPress} style={styles.container}>
        <View style={styles.info}>
          <Text variant={width < 480 ? 'titleSmall' : 'titleMedium'}>
            {cluster.topic}
          </Text>
          <View style={styles.infoIcons}>
            <View style={styles.icon}>
              <Icon3dPerspective
                size={24}
                onPress={() =>
                  navigation.push('ClusterCards', {
                    clusterId: cluster.id,
                    nodeId: cluster.nodeId,
                  })
                }
                label={cluster.articles.length.toString()}
              />
            </View>
            <View style={styles.icon}>
              <Icon3dContributions
                size={24}
                label={contributionCount.toString()}
                onPress={() =>
                  navigation.push('ClusterContributions', {
                    clusterId: cluster.id,
                  })
                }
              />
            </View>
            <View style={styles.icon}>
              <Icon3dVoteCount size={24} label={voteCount.toString()} />
            </View>
          </View>
        </View>
        <IconButton icon={IconGoTo} onPress={onPress} />
      </Pressable>
    </Animated.View>
  )
}

const useStyle = createStyle(({ theme }) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingRight: 0,
    borderBottomColor: theme.colors.surfaceVariant,
    borderBottomWidth: 1,
  },
  info: {
    rowGap: theme.spacing(2),
    flex: 1,
  },
  infoIcons: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  icon: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    width: theme.spacing(16),
  },
}))
