import * as React from 'react'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Surface, Text } from 'react-native-paper'
import { Article, Contribution } from '../types'
import { ContributionType } from '../../../generated/graphql'
import { useTypeStrings } from '../../../hooks/use-type-strings'
import { IconButton } from 'react-native-paper'
import {
  Icon3dChallenge,
  Icon3dEdit,
  Icon3dInquiry,
  IconClose,
  IconFullScreen,
} from '../../../utils/meldd-icons'
import { useTheme } from '../../../hooks/use-theme'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../components/Icon'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import { NavigationButtons } from '../../../components/NavigationButtons'
import { useNavigation } from '@react-navigation/native'
import { Row } from '../../../components/containers/Row'
import { AppNavigation } from '../../../navigation/types'
import { WhiteCard } from '../../../components/containers/WhiteCard'
import Column from '../../../components/containers/Column'
import { ContributionDecisionBox } from '../../Contributions/components/ContributionDecisionBox'
import { BumpButtons } from '../../Contributions/components/BumpButtons'
import { useApolloClient } from '@apollo/client'
import { CancelContribution } from '../../Contributions/components/ContributionCard'

type NonReservedContributionType = Exclude<
  ContributionType,
  ContributionType.Reserved
>

interface ContributionDetailsProps {
  perspective: Article
  contributionId: string | null
  onNext: () => void
  onPrevious: () => void
  onClose: () => void
}

const useContributionTypeColors = (type: NonReservedContributionType) => {
  const theme = useTheme()
  const colors = {
    [ContributionType.Edit]: theme.colors.editBkg,
    [ContributionType.Challenge]: theme.colors.challengeBkg,
    [ContributionType.Inquiry]: theme.colors.inquiryBkg,
  }
  return colors[type as keyof typeof colors]
}

const HeaderIcon = ({ type }: { type: NonReservedContributionType }) => {
  const { t } = useTranslation('contributions')
  const headers = {
    [ContributionType.Inquiry]: {
      icon: Icon3dInquiry,
      type: ContributionType.Inquiry,
      label: t('Inquiry'),
    },
    [ContributionType.Challenge]: {
      icon: Icon3dChallenge,
      type: ContributionType.Challenge,
      label: t('Challenge'),
    },
    [ContributionType.OwnerEdit]: {
      icon: Icon3dEdit,
      type: ContributionType.OwnerEdit,
      label: t('Owner Edit'),
    },
    [ContributionType.Edit]: {
      icon: Icon3dEdit,
      type: ContributionType.Edit,
      label: t('Edit'),
    },
  }

  if (!headers[type]) return null
  return (
    <Row wrap={false} columnGap={4} alignItems="center">
      <Icon source={headers[type].icon} size={24} />
      <Text variant={'titleMedium'}>{t(headers[type].label)}</Text>
    </Row>
  )
}

export function ContributionDetails(props: ContributionDetailsProps) {
  const { contributionId, onClose, onNext, onPrevious, perspective } = props
  const { labelInput, labelMotivation } = useTypeStrings()
  const client = useApolloClient() // Add this hook

  const contribution = useMemo(() => {
    return perspective.contributions.find(
      (contribution) => contribution.id === contributionId
    )
  }, [perspective.contributions, contributionId])

  const styles = useStyle()
  const bkgColor = useContributionTypeColors(
    contribution?.contributionType as NonReservedContributionType
  )

  const navigation = useNavigation<AppNavigation>()
  if (!contribution) return null
  return (
    <Surface
      style={[styles.container, { backgroundColor: bkgColor }]}
      elevation={3}
    >
      {
        <Column gap={2} style={{ padding: 10 }}>
          <Row justifyContent="space-between">
            <Row alignItems="center">
              <HeaderIcon
                type={
                  contribution.contributionType as NonReservedContributionType
                }
              />
              <IconButton
                onPress={() =>
                  navigation.navigate('PerspectiveContribution', {
                    contributionId: contribution.id,
                  })
                }
                icon={IconFullScreen}
              />
            </Row>
            <Row>
              <NavigationButtons
                onPrevious={onPrevious}
                onNext={onNext}
                disabled={false}
              />
              <IconButton onPress={onClose} icon={IconClose} />
            </Row>
          </Row>
          {
            <WhiteCard>
              <View style={{ padding: 10, gap: 2 }}>
                <Text variant={'labelMedium'}>
                  {labelInput[contribution.contributionType]}:{' '}
                </Text>
                <Text variant={'bodyMedium'}>"{contribution.input}"</Text>
              </View>
            </WhiteCard>
          }
          {contribution.motivation && (
            <WhiteCard>
              <View style={{ padding: 10, gap: 2 }}>
                <Text variant={'labelMedium'}>
                  {labelMotivation[contribution.contributionType]}:{' '}
                </Text>
                <Text variant={'bodyMedium'}>"{contribution.motivation}"</Text>
              </View>
            </WhiteCard>
          )}

          {perspective.isOwner && (
            <ContributionDecisionBox
              contribution={contribution}
              onStatusUpdated={() => {
                client.refetchQueries({ include: ['article'] })
              }}
            />
          )}
          <Row justifyContent="space-between">
            <View>
              <CancelContribution contribution={contribution} />
            </View>
            <BumpButtons contribution={contribution} />
          </Row>
        </Column>
      }
    </Surface>
  )
}

const useStyle = createStyle(({ theme }) => {
  return {
    container: {
      position: 'absolute',

      bottom: 0,
      left: 0,
      right: 0,
      paddingVertical: theme.spacing(5),
      marginTop: -theme.spacing(5),
      paddingHorizontal: theme.spacing(4),
      borderTopLeftRadius: theme.spacing(5),
      borderTopRightRadius: theme.spacing(5),
      flexDirection: 'column',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    },
    buttons: {
      paddingVertical: 5,
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
  }
})
