import { AttachFile, IconImport } from '../../../utils/meldd-icons'
import { useTranslation } from 'react-i18next'
import { importFile } from '../../../utils/import-utils'
import { useAuthContext } from '../../../auth/auth-context'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { useWindowDimensions } from 'react-native'
import Dropdown from '../../../components/Dropdown'
import { useMemo } from 'react'

export const ImportButton = ({ clusterId }: { clusterId: string }) => {
  const { t } = useTranslation('clusters')
  const { authAction } = useAuthContext()
  const navigation = useNavigation<AppNavigation>()
  if (!clusterId) {
    return null
  }

  const items = useMemo(
    () => [
      {
        id: 'doc',
        label: t('docx'),
        icon: AttachFile,
        action: () =>
          authAction(navigation, () => importFile(navigation, clusterId)),
      },
    ],
    []
  )

  return (
    <Dropdown
      items={items}
      icon={IconImport}
      showSearch={false}
      placeholder={t('Import')}
      onSelect={(item) => {
        item.action()
      }}
      useLocalFilter={true} // Default behavior
    />
  )

  // return width > 400 ? (
  //   <Button
  //     mode={'outlined'}
  //     icon={IconImport}
  //     onPress={() => {
  //       authAction(navigation, () => importFile(navigation, clusterId))
  //     }}
  //   >
  //     {t('Import')}
  //   </Button>
  // ) : (
  //   <IconButton
  //     mode="contained"
  //     icon={IconImport}
  //     onPress={() => {
  //       authAction(navigation, () => importFile(navigation, clusterId))
  //     }}
  //   />
  // )
}
