import 'fast-text-encoding'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import { decode } from 'base64-arraybuffer'
import { AppNavigation } from '../navigation/types'
import Toast from 'react-native-root-toast'
import { MessageType } from './message-type'
import * as Sentry from '@sentry/react-native'
import { Platform } from 'react-native'
import i18n from '../i18n'
const mammoth = require('mammoth/mammoth.browser')

function readFile(uri: string) {
  if (Platform.OS === 'web') {
    return fetch(uri)
      .then((response) => response.arrayBuffer())
      .then((buffer) => Buffer.from(buffer).toString('base64'))
  }
  return FileSystem.readAsStringAsync(uri, {
    encoding: 'base64',
  })
}

export const importFile = async (
  navigation: AppNavigation,
  clusterId: string
) => {
  try {
    const result = await DocumentPicker.getDocumentAsync({
      multiple: false,
      copyToCacheDirectory: true,
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    })

    if (result.canceled) {
      Toast.show('Canceled', MessageType.info)
      return
    }
    const file = await readFile(result.assets[0].uri)
    const arrayBuffer = decode(file)
    const transformDocument = mammoth.transforms._elements(function (
      element: any
    ) {
      if (['image', 'bookmarkStart', 'bookmarkEnd'].includes(element?.type)) {
        return {}
      }
      return element
    })

    const htmlResult = await mammoth.convertToHtml(
      { arrayBuffer },
      { transformDocument }
    )

    if (!htmlResult.value) {
      Toast.show('No content', MessageType.info)
      return
    }

    navigation.navigate('CreateArticle', {
      clusterId,
      content: htmlResult.value,
      title: result.assets[0].name.split('.')[0],
    })
  } catch (e) {
    Toast.show('Error importing: ' + (e as Error).message, {
      ...MessageType.error,
      duration: Toast.durations.LONG,
      position: Toast.positions.CENTER,
    })
    console.error(e)
    Sentry.captureException(e)
  }
}

export const downloadFile = async (url: string, fileName: string) => {
  if (Platform.OS === 'web') {
    const response = await fetch(url)
    const blob = await response.blob()
    const link = document.createElement('a')

    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
    return true
  }

  const downloadDest = `${FileSystem.documentDirectory}${fileName}`
  const { uri } = await FileSystem.downloadAsync(url, downloadDest)

  return !!uri
}
