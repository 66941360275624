import { html } from '@meldd/article-editor'
import * as React from 'react'
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Keyboard,
  LayoutChangeEvent,
  Platform,
  RefreshControl,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import AutoHeightWebView from 'react-native-autoheight-webview'
import { WebView, WebViewMessageEvent } from 'react-native-webview'
import { useFeatures } from '../../contexts/FeatureProvider'
import { Toolbar } from './Toolbar'
import { WebViewWeb, WebViewWebRef } from './WebViewWeb'
import { TutorialComponent } from '../Tutorial/TutorialComponent'
import * as Sentry from '@sentry/react-native'

export type SelectionInfo = {
  start: number
  end: number
  html: string
}

export interface EditorContribution {
  id: string
  start: number
  end: number
  type: number
}

export interface ContributionPosition {
  id: string
  top: number
  height: number
}

interface ArticleEditorProps {
  style?: ViewStyle
  key?: string
  useContainer?: boolean
  editorMode: 'edit' | 'select'
  onSelection?: (info: SelectionInfo) => void
  onContribution?: (id: string | null) => void
  onHtmlContent?: (html: string, text: string) => void
  contributions?: EditorContribution[]
  refreshing?: boolean
  onRefresh?: () => void
  onScrollEnd?: (scrollPosition: {
    scrollTop: number
    scrollLeft: number
    viewportHeight: number
    contentHeight: number
  }) => void
  onContributionPositions: (positions: ContributionPosition[]) => void
}

export interface ArticleEditorRef {
  requestContent: () => void
  lockSelection: (value: boolean) => void
  showContributions: (value: boolean) => void
  selectContribution: (id?: string) => void
  selectContributionChange: (change: -1 | 1) => void
  setContributions: (contributions: EditorContribution[]) => void
  setEditorContent: (content: string) => void
  setSelectionFromContribution: (contribution: EditorContribution) => void
  clearSelection: () => void
  dismissKeyboard: () => void
}

const ArticleEditorImpl = (
  props: ArticleEditorProps,
  ref: Ref<ArticleEditorRef>
) => {
  const { refreshing, onRefresh } = props
  const editorWebView = useRef<WebView | WebViewWebRef>(null)
  const messageQueue = useRef<string[]>([])
  const [viewHeight, setViewHeight] = useState(0)
  const [initialized, setInitialized] = useState(false)
  const [editorLoaded, setEditorLoaded] = useState(false)
  const containerRef = useRef<View>(null)
  const { showEditorLogs } = useFeatures()

  /**
   * Check if the script is valid JSON, inject script function will fail silently if is not
   */

  const verifyJSON = (object: object) => {
    // Simulates the eval used on the inject script function
    const injectScriptMock = (args: string) => JSON.parse(args)

    const script = `injectScriptMock(\`${JSON.stringify(object)}\`)`
    try {
      eval(script)
      return true
    } catch (e) {
      console.error(e)
      // Log the error message to identify the problematic character
      let problematicCharacter = ''
      let problematicContext = ''
      if (e instanceof SyntaxError) {
        const position = e.message.match(/position (\d+)/)
        if (position) {
          const pos = +position[1] + 1
          problematicCharacter = script[pos]
          problematicContext = script.slice(pos, pos + 12)
          console.error(`Problematic character: ${script[pos]}`)
        }
      }
      const errorMessage =
        'Invalid JSON on loading perspective: ' +
        script +
        (problematicCharacter
          ? ' Problematic Character is `' +
            problematicCharacter +
            '` and context is `' +
            problematicContext +
            '`'
          : '')

      Sentry.captureMessage(errorMessage)
      console.error(errorMessage)

      return false
    }
  }
  const postMessage = (obj: object) => {
    const valid = verifyJSON(obj)
    if (!valid) {
      return
    }
    const script = `onHostMessage(\`${JSON.stringify(obj)}\`)`

    if (initialized) {
      editorWebView.current?.injectJavaScript(script)
      return
    }

    const scripts = [...messageQueue.current, script]
    messageQueue.current = scripts
  }
  useEffect(() => {
    if (initialized && messageQueue.current.length) {
      for (let script of messageQueue.current) {
        editorWebView.current?.injectJavaScript(script)
      }
      messageQueue.current = []
    }
  }, [initialized])

  useImperativeHandle(
    ref,
    () => {
      return {
        requestContent: () => postMessage({ type: 'request-content' }),
        lockSelection: (value: boolean) =>
          postMessage({ type: 'lock-selection', value }),
        showContributions: (value: boolean) =>
          postMessage({ type: 'show-contributions', value }),
        selectContribution: (id?: string) =>
          postMessage({ type: 'select-contribution', id }),
        setSelectionFromContribution: (value: EditorContribution) =>
          postMessage({ type: 'set-selection', value }),
        setContributions: (contributions: EditorContribution[]) =>
          postMessage({
            type: 'set-contributions',
            contributions,
          }),
        selectContributionChange: (change: -1 | 1) =>
          postMessage({ type: 'select-contribution', change }),
        clearSelection: () => postMessage({ type: 'clear-selection' }),
        setEditorContent: (content: string) => {
          const processedContent = content
            .replace(/"/g, '&quot;')
            .replace(/`/g, '&#96;')
            .replace(/'/g, '&apos;')
            .replace(/\n/g, '')
            .replace(/\t/g, '\\t')
            .replace(/\\/g, '&bsol;')

          postMessage({
            type: 'set-editor-content',
            content: processedContent,
          })
        },
        dismissKeyboard: () => {
          Keyboard.dismiss()
        },
      }
    },
    [postMessage]
  )
  const init = useMemo(() => {
    return `window.articleEditorInit('${props.editorMode}', ${showEditorLogs})`
  }, [props.editorMode, showEditorLogs])

  const [editorState, setEditorState] = React.useState({})
  const onMessage = (event: WebViewMessageEvent) => {
    if (!event.nativeEvent.data || typeof event.nativeEvent.data !== 'string') {
      return
    }
    if (event.nativeEvent.data.startsWith('setImmediate$')) {
      return
    }
    const message = JSON.parse(event.nativeEvent.data) as {
      type: string
      data: any
      text?: string
    }
    switch (message.type) {
      case 'meldd-menu-bridge':
        setEditorState(message.data)
        break
      case 'meldd-selection':
        props.onSelection?.(message.data)
        break

      case 'meldd-content':
        props.onHtmlContent?.(message.data, message.text!)
        break
      case 'contribution-selected':
        props.onContribution?.(message.data || 'none')
        break
      case 'contribution-positions':
        props.onContributionPositions?.(message.data)
        break
      case 'init-done':
        setInitialized(true)
        break

      case 'scroll':
        props.onScrollEnd?.({
          ...message.data,
          viewportHeight: viewHeight,
          contentHeight: message.data.contentHeight - 32, // Remove the paddings
        })
        break
    }
  }
  const webOnload = () => {
    setEditorLoaded(true)
  }

  useEffect(() => {
    if (editorLoaded && init) {
      editorWebView.current?.injectJavaScript(init)
    }
  }, [editorLoaded, init])

  return (
    <View style={[props.style, { flex: 1 }]} ref={containerRef}>
      {props.editorMode === 'edit' && (
        <Toolbar
          editorState={editorState}
          onMenuItem={(msg) => {
            editorWebView.current?.injectJavaScript(
              `onHostMessage('${JSON.stringify(msg)}');`
            )
          }}
        />
      )}
      <TutorialComponent
        style={{ height: '100%' }}
        tutorialId="article_editor"
        offsetHeight={20}
      >
        {Platform.OS == 'web' ? (
          <WebViewWeb
            ref={editorWebView}
            title={'Editor'}
            src={`/editor.html`}
            onLoad={webOnload}
            style={{ width: '100%', height: '100%' }}
            onMessage={onMessage}
          />
        ) : (
          <ScrollView
            contentContainerStyle={{ height: '100%' }}
            refreshControl={
              onRefresh && (
                <RefreshControl
                  refreshing={refreshing || false}
                  onRefresh={onRefresh}
                />
              )
            }
          >
            <AutoHeightWebView
              hideKeyboardAccessoryView={true}
              keyboardDisplayRequiresUserAction={true}
              // nestedScrollEnabled={true}
              style={[styles.webview]}
              ref={editorWebView as any}
              onMessage={onMessage}
              originWhitelist={['*']}
              dataDetectorTypes={'none'}
              domStorageEnabled={false}
              bounces={false}
              javaScriptEnabled={true}
              source={{ html }}
              // scalesPageToFit={true}
              customScript={init}
              viewportContent={
                'width=device-width,user-scalable=1.0,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0'
              }
            />
          </ScrollView>
        )}
      </TutorialComponent>
    </View>
  )
}
export const ArticleEditor = forwardRef<ArticleEditorRef, ArticleEditorProps>(
  ArticleEditorImpl
)
const styles = StyleSheet.create({
  webview: {
    backgroundColor: 'transparent',
  },
})
