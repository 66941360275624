import { useContext, useEffect, useState } from 'react'
import { ControlledMobileInput } from '../../components/form/ControlledMobileInput'
import { useForm } from 'react-hook-form'
import { LocalizationUtils } from '../../utils/localization'
import { useTheme } from '../../hooks/use-theme'
import { useModalDialog } from '../../components/ModalDialogProvider'
import {
  createStyle,
  useGlobalStyles,
} from '../../contexts/GlobalStylesProvider'
import { AuthContext } from '../../auth/auth-context'
import { MessageType } from '../../utils/message-type'
import Toast from 'react-native-root-toast'
import { goAfterLogin } from './utils'
import { useTranslation } from 'react-i18next'
import {
  ERROR_CODES,
  getErrorCode,
  useErrorTranslation,
} from '../../utils/error-utils'
import { useLoginMobileOtpMutation } from '../../generated/graphql'
import { Button, Text } from 'react-native-paper'
import Checkbox from 'expo-checkbox'
import ControlledCodeInput from '../../components/form/ControlledCodeInput'
import { AppNavigatorParams } from '../../navigation/types'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/native'
import { Platform, View } from 'react-native'
interface FormShape {
  mobileNumber: string
  mobileCountryCode: string
  otp: string
}

export type FormStatus = {
  mobileNumber: string
  mobileCountryCode: string
  isSubmitting: boolean
}

const OTPLogin = ({
  googleTokenId,
  wechatCode,
  returnPath,
  onFormStatus,
}: {
  googleTokenId: string
  wechatCode: string
  returnPath: string
  onFormStatus: ({
    mobileNumber,
    mobileCountryCode,
    isSubmitting,
  }: FormStatus) => void
}) => {
  const { setDialog } = useModalDialog()
  const navigation = useNavigation<StackNavigationProp<AppNavigatorParams>>()
  const { lastUserData, setCurrentUser } = useContext(AuthContext)
  const [isPolicyChecked, setIsPolicyChecked] = useState<boolean>(false)
  const [isOTPAgreed, setIsOTPAgreed] = useState<boolean>(false)
  const { t } = useTranslation('login_register_reset')
  const [doLoginUser, { client }] = useLoginMobileOtpMutation()

  const tError = useErrorTranslation()

  const globalStyles = useGlobalStyles()
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    getValues,
    trigger,
  } = useForm<FormShape>({
    defaultValues: async () => {
      const countryCode =
        lastUserData.mobileCountryCode ||
        (await LocalizationUtils.get().countryCode())
      return {
        mobileNumber: lastUserData.mobileNumber || '',
        mobileCountryCode: `${countryCode}`,
        otp: '',
      }
    },
    mode: 'onTouched',
  })
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false)
  const [mobileNumber, mobileCountryCode] = watch([
    'mobileNumber',
    'mobileCountryCode',
  ])
  useEffect(
    () => onFormStatus({ mobileNumber, mobileCountryCode, isSubmitting }),
    [isSubmitting, mobileNumber, mobileCountryCode]
  )
  const submit = async (data: FormShape) => {
    const { mobileNumber, mobileCountryCode, otp } = data

    try {
      const result = await doLoginUser({
        variables: {
          input: {
            mobileNumber,
            mobileCountryCode: parseInt(mobileCountryCode || '0'),
            otp,
            // If present will link the account to this google account
            googleToken: googleTokenId
              ? { token: googleTokenId, os: Platform.OS }
              : undefined,
            wechatCode: wechatCode ?? undefined,
          },
        },
      })
      await client.clearStore()
      setCurrentUser(result.data!.loginMobileOTP)
      goAfterLogin({ navigation, returnPath: returnPath || '' })
      Toast.show(t('Login successful'), MessageType.info)
    } catch (e) {
      console.error(e)
      Toast.show(tError(e), MessageType.error)
    }
  }

  const styles = useStyles()
  const isDisabled =
    !isMobileNumberValid ||
    !isValid ||
    isSubmitting ||
    !isPolicyChecked ||
    !isOTPAgreed
  return (
    <>
      <ControlledMobileInput
        control={control}
        names={['mobileCountryCode', 'mobileNumber']}
        error={!!errors.mobileNumber}
        getValues={getValues}
        trigger={trigger}
        helperText={errors.mobileNumber?.message}
        wrapperStyle={globalStyles.flexNoWrap}
        onIsValid={setIsMobileNumberValid}
      ></ControlledMobileInput>
      <ControlledCodeInput
        isOTP={true}
        mobileNumber={mobileNumber}
        countryCode={mobileCountryCode}
        name="otp"
        control={control}
        error={errors.otp}
        errorHandler={(e) => {
          const errorCode = getErrorCode(e)
          if (errorCode === ERROR_CODES.NOT_FOUND) {
            setDialog({
              title: t('No such user'),
              body: <Text>{t('Do you want to register a new account?')}</Text>,
              primaryLabel: t('Sign Up'),
              primaryAction: async () => {
                setDialog(null)
                navigation.navigate('Register', {
                  mobileCountryCode,
                  mobileNumber,
                  returnPath,
                })
              },
              secondaryAction: () => {
                setDialog(null)
              },
              secondaryLabel: t('Retry'),
            })
            return true
          }
          return false
        }}
      />
      <View style={styles.linksCtn}>
        <Checkbox
          disabled={false}
          value={isPolicyChecked}
          onValueChange={(newValue: boolean) => setIsPolicyChecked(newValue)}
        />
        <Button onPress={() => navigation.navigate('PrivacyPolicy')}>
          <Text style={{ textDecorationLine: 'underline' }}>
            {t('Privacy policy')}
          </Text>
        </Button>
      </View>
      <View style={styles.linksCtn}>
        <Checkbox
          style={{ marginBottom: 0, marginRight: 10 }}
          disabled={false}
          value={isOTPAgreed}
          onValueChange={(newValue: boolean) => setIsOTPAgreed(!isOTPAgreed)}
        />
        <Text>{t('verificationOTPMessage')}</Text>
      </View>
      {googleTokenId && (
        <View style={styles.googleSyncInfoBox}>
          <Text style={styles.googleSyncInfoText}>
            {t('sing-google-sync-info')}
          </Text>
        </View>
      )}
      {wechatCode && (
        <View style={styles.googleSyncInfoBox}>
          <Text style={styles.googleSyncInfoText}>
            {t('sing-wechat-sync-info')}
          </Text>
        </View>
      )}
      <View style={styles.linksCtn}>
        <Button
          style={{ flex: 1 }}
          mode={'contained'}
          onPress={handleSubmit(submit)}
          disabled={isDisabled}
        >
          <Text style={{ color: isDisabled ? 'black' : 'white' }}>
            {googleTokenId ? t('sing-google-sync') : t('Sign In')}
          </Text>
        </Button>
      </View>
    </>
  )
}

export default OTPLogin

const useStyles = createStyle(({ theme }) => ({
  container: {
    padding: theme.components.pageContainer.padding,
    paddingTop: theme.spacing(10),
  },
  row: {
    marginVertical: theme.spacing(0),
  },
  flex: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  notRegistered: {
    flexDirection: 'row',
    paddingTop: theme.spacing(10),
    alignContent: 'center',
    justifyContent: 'center',
  },
  linksCtn: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignContent: 'stretch',
    flexGrow: 1,
    alignItems: 'center',
    marginVertical: 5,
  },
  googleSyncInfoBox: {
    backgroundColor: theme.colors.secondaryContainer, // Assuming you have a color in your theme
    padding: theme.spacing(2),
    borderRadius: 8,
    marginVertical: theme.spacing(2),
  },
  googleSyncInfoText: {
    color: theme.colors.text, // Assuming you have a text color in your theme
  },
}))
