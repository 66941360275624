import { useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useTranslation } from 'react-i18next'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { useTheme } from '../../hooks/use-theme'
import MelddTabs from '../../components/containers/MelddTabs'
import { IconAssignedTask, IconCompleted } from '../../utils/meldd-icons'
import { useAuthSecureContext } from '../../auth/auth-context'
import { MyContributions } from './components/task-contributors'

export function MyTasksScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'MyTasks'>) {
  useAuthSecureContext({ onlyAuthenticated: true })

  const { t } = useTranslation(['tasks', 'common'])
  const { tab } = route.params
  const [selectedTab, setSelectedTab] = useState<'completed' | 'pending'>(tab)
  const styles = useStyle()

  const tabs = [
    {
      label: t('pending-tasks'),
      value: 'pending',
      icon: IconAssignedTask,
    },
    { label: t('completed-tasks'), value: 'completed', icon: IconCompleted },
  ]

  return (
    <ScreenWrapper contentContainerStyle={styles.pageContainer}>
      <MelddTabs
        items={tabs}
        selected={selectedTab}
        onSelect={(value) => {
          setSelectedTab(value as 'pending' | 'completed')
        }}
      />
      {selectedTab === 'pending' && <MyContributions status="pending" />}
      {selectedTab === 'completed' && <MyContributions status="completed" />}
    </ScreenWrapper>
  )
}

const useStyle = () => {
  const theme = useTheme()
  const globalStyles = useGlobalStyles()
  return useMemo(
    () =>
      StyleSheet.create({
        ...globalStyles,
        row: {
          flexDirection: 'row',
          columnGap: theme.spacing(2),
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        },
        deleteButton: {
          marginTop: 10,
          marginBottom: 10,
          flexGrow: 1,
          borderRadius: 0,
          backgroundColor: theme.colors.warn,
        },
      }),
    [theme]
  )
}
export default MyTasksScreen
