import { StackNavigationProp } from '@react-navigation/stack'
import * as React from 'react'
import { Appbar, Divider, IconButton, Menu } from 'react-native-paper'
import { AppNavigatorParams } from '../navigation/types'
import { useExportDocxLazyQuery, useLogoutMutation } from '../generated/graphql'
import { useContext, useRef } from 'react'
import { AuthContext, useAuthContext } from '../auth/auth-context'
import { usePushToken } from '../contexts/PushTokenProvider'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import * as Sentry from '@sentry/react-native'
import { ShareModalMenu } from './share.modal'
import { ExportToDocxMenuIcon } from './export-to-docs.button'
import { Article } from '../screens/Perspective/types'
import {
  IconAssignTask,
  IconEagleView,
  IconPerspectiveSettings,
} from '../utils/meldd-icons'
import { getReturnPath } from '../screens/Login/login-required.modal'
import { useHeaderButton } from '../contexts/HeaderButtonProvider'
import { MelddTaskTargetType } from '../screens/tasks/task.types'

interface NavigationBarMenuProps {
  navigation: StackNavigationProp<AppNavigatorParams>
  perspective: Article
}

export default function PerspectiveScreenBarMenu({
  navigation,
  perspective,
}: NavigationBarMenuProps) {
  const [visible, setVisible] = React.useState(false)
  const { isAnonymous } = useAuthContext()
  const [LogoutUser] = useLogoutMutation()
  const { meta: eagleView, setMeta: setEagleView } =
    useHeaderButton<boolean>('eagleView')

  const { handler: createTaskHandler } = useHeaderButton<boolean>('createTask')

  const authCtx = useContext(AuthContext)
  const pushToken = usePushToken()
  const { t } = useTranslation('menuItems')
  const [exportDocx, { loading: loadingExportDocx }] = useExportDocxLazyQuery()

  const logout = async () => {
    authCtx.clearCurrentUser()
    try {
      await LogoutUser(
        pushToken ? { variables: { input: { token: pushToken } } } : undefined
      )
    } catch (error) {
      console.error('Failed to logout', error)
      Sentry.captureException(error)
    }
  }
  if (!perspective) {
    return null
  }
  /**
   * NOTE! If you change state and then navigate, the state change fires on an unmounting component.
   * Thats why we have a timeout of 1 ms
   */
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={
        <Appbar.Action icon="dots-vertical" onPress={() => setVisible(true)} />
      }
    >
      {perspective.isOwner && (
        <Menu.Item
          leadingIcon={IconPerspectiveSettings}
          title={t('Edit')}
          onPress={() =>
            navigation.navigate('UpdateArticle', {
              articleId: perspective.id,
            })
          }
        />
      )}

      <ExportToDocxMenuIcon
        navigation={navigation}
        perspective={perspective}
        exportDocsQuery={exportDocx}
        loading={loadingExportDocx}
      />
      <ShareModalMenu
        url={Platform.OS === 'web' ? location.href.split('#')[0] : ''}
      />

      <Menu.Item
        leadingIcon={IconAssignTask}
        title={t('create-task')}
        onPress={() => createTaskHandler?.()}
      />

      <Menu.Item
        leadingIcon={() => (
          <IconEagleView color={eagleView ? '#C6A23F' : '#000000'} size={24} />
        )}
        title={t('eagle-view')}
        onPress={() => {
          setEagleView(!eagleView)
          setVisible(false)
        }}
      />
      <Divider />
      {isAnonymous ? (
        <Menu.Item
          leadingIcon="login"
          onPress={() => {
            setVisible(false)
            setTimeout(
              () =>
                navigation.navigate('LoginMobileNumber', {
                  returnPath: getReturnPath(navigation),
                }),
              1
            )
          }}
          title={t('login')}
        />
      ) : (
        <Menu.Item
          leadingIcon="logout"
          onPress={() => {
            setVisible(false)
            logout().then()
          }}
          title={t('Log out')}
        />
      )}
    </Menu>
  )
}
