import React, { useRef, useEffect, useMemo, useState } from 'react'
import { Dimensions, StyleProp, View, ViewStyle } from 'react-native'
import { TutorialElement, useTutorial } from './TutorialContext'

type Props = {
  tutorialId: string
  children: React.ReactNode
  offsetX?: number
  offsetY?: number
  offsetWidth?: number
  offsetHeight?: number
  disabled?: boolean
  style?: StyleProp<ViewStyle>
}

export const TutorialComponent: React.FC<Props> = ({
  tutorialId,
  children,
  offsetX = 0,
  offsetY = 0,
  offsetWidth = 0,
  offsetHeight = 0,
  disabled = false,
  style,
}) => {
  const viewRef = useRef<View>(null)

  const { registerElement } = useTutorial()

  useEffect(() => {
    if (!viewRef.current) {
      return
    }
    if (disabled) return

    viewRef.current?.measure((x, y, width, height, pageX, pageY) => {
      const windowWidth = Dimensions.get('window').width
      const windowHeight = Dimensions.get('window').height

      // Check if element is within visible bounds
      if (x < 0 || y < 0 || width <= 0 || height <= 0) {
        return
      }
      if (x + width > windowWidth || y + height > windowHeight) {
        return
      }
      const position = {
        x: pageX + offsetX,
        y: pageY + offsetY,
        width: width + offsetWidth,
        height: height + offsetHeight,
      }
      registerElement(tutorialId, position)
    })
  }, [tutorialId, disabled])

  return (
    <View ref={viewRef} style={style}>
      {children}
    </View>
  )
}
