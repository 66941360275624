import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  RefreshControl,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Button } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { useAuthContext } from '../../auth/auth-context'
import Row from '../../components/containers/Row'
import { Copyright } from '../../components/Copyright'
import EagleView from '../../components/eagle-view/eagle-view'
import { LevelIndicator } from '../../components/LevelIndicator'
import MButton from '../../components/m-button/MButton'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useNode } from '../../contexts/CurrentNodeProvider'
import {
  createStyle,
  useGlobalStyles,
} from '../../contexts/GlobalStylesProvider'
import { useAvailableClustersLazyQuery } from '../../generated/graphql'
import { useTheme } from '../../hooks/use-theme'
import { useWechatShareInit } from '../../hooks/use-wechat-share'
import { AppNavigation, AppNavigatorParams } from '../../navigation/types'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'
import { IconAdd } from '../../utils/meldd-icons'
import { TargetType } from '../PerspectiveCards/types'
import { TaskPendingIcon } from '../tasks/components/task-pending-icon'
import { MelddTaskTargetType } from '../tasks/task.types'
import { Cluster } from './types'
import * as Sentry from '@sentry/react-native'
import { TutorialComponent } from '../../components/Tutorial/TutorialComponent'
import { Tutorial } from '../../components/Tutorial/Tutorial'
import { TutorialKey } from '../../components/Tutorial/TutorialStorage'
import { ClusterCard } from './cluster.card'
import { MelddSearchBar } from '../../components/form/MelddSearchBar'
import { IconFilter } from '../../utils/meldd-icons'

const ClusterListTutorial = () => {
  const { t } = useTranslation('tutorials')

  return (
    <Tutorial
      steps={[{ tutorialId: 'create_cluster', message: t('create_cluster') }]}
      tutorialKey={TutorialKey.CLUSTER_SCREEN}
    />
  )
}

const NoDataMessage = ({
  nodeId,
  searchQuery,
}: {
  nodeId: string
  searchQuery: string
}) => {
  const { t } = useTranslation('clusters')
  const navigation = useNavigation<AppNavigation>()
  return (
    <Text
      style={{
        textAlign: 'center',
        fontSize: 12,
      }}
    >
      {t('no-clusters-found')}{' '}
      <TouchableOpacity
        onPress={() => navigation.navigate('CreateCluster', { nodeId })}
      >
        <Text style={{ textDecorationLine: 'underline' }}>
          {t('create-cluster')}
        </Text>
      </TouchableOpacity>
      {searchQuery && (
        <>
          {' '}
          <Text style={{}}>{t('or')}</Text>{' '}
          <TouchableOpacity
            onPress={() =>
              navigation.navigate('Search', { nodeId, searchQuery })
            }
          >
            <Text style={{ textDecorationLine: 'underline' }}>
              {t('make-a-global-search')}
            </Text>
          </TouchableOpacity>
        </>
      )}
    </Text>
  )
}

export function ClusterListScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'ClusterList'>) {
  const isFocused = useIsFocused()
  const { params } = route
  const { t } = useTranslation('clusters')
  const globalStyles = useGlobalStyles()
  const { authAction } = useAuthContext()
  const nodeId = params?.nodeId || ''
  const [eagleViewOpen, setEagleViewOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchInput, setSearchInput] = useState('')

  const styles = useStyle()
  const [doLoadClusters, { data, loading }] = useAvailableClustersLazyQuery()

  const { setCurrentNode, currentNode } = useNode()

  const refresh = useCallback(
    async ({ searchQuery = '' }: { searchQuery?: string }) => {
      if (!nodeId) {
        navigation.navigate('ListNodes')
        return
      }
      try {
        await doLoadClusters({
          variables: { input: { nodeId, searchQuery } },
          fetchPolicy: 'cache-and-network',
        })
        setCurrentNode(nodeId)
      } catch (e) {
        console.error(e)
        Toast.show((e as Error).message)
        Sentry.captureException(e)
      }
    },
    [nodeId]
  )

  const refreshRoom = useCallback(() => {
    if (!nodeId) {
      return
    }
    refresh({ searchQuery })
  }, [searchQuery])

  useSocketRoom(nodeId).event(SocketEvents.Refresh, refreshRoom)

  useEffect(() => {
    if (isFocused) {
      refresh({})
    }
  }, [isFocused, refresh])

  useEffect(() => {
    setSearchInput('')
    setSearchQuery('')
  }, [nodeId])

  const theme = useTheme()

  const totalArticles = useMemo(() => {
    return (
      data?.availableClusters?.reduce(
        (acc, cluster) => acc + cluster.articles.length,
        0
      ) || 0
    )
  }, [data])

  const totalVotes = useMemo(() => {
    return (
      data?.availableClusters?.reduce((acc, cluster) => {
        return acc + cluster.articleVotes.length // Count votes for each cluster
      }, 0) || 0
    )
  }, [data])

  useWechatShareInit(() => {
    if (!nodeId || !data) return null
    return {
      title: currentNode?.name || '',
      description: `${data.availableClusters.length} cluster(s), with ${totalArticles} perspective(s), and ${totalVotes} vote(s)`,
    }
  }, [nodeId, data, totalArticles, totalVotes])

  return (
    <View style={[globalStyles.pageContainer]}>
      <Row justifyContent={'space-between'} alignItems={'center'}>
        {/* TOP LEFT */}
        <Row alignItems={'center'} columnGap={4} flex={1}>
          <TutorialComponent tutorialId="create_cluster">
            <Button
              icon={IconAdd}
              mode="elevated"
              style={{ width: 170 }} // fix the width for the tutorial highlight
              onPress={() =>
                authAction(navigation, () =>
                  navigation.push('CreateCluster', {
                    nodeId: nodeId,
                  })
                )
              }
            >
              {t('Create cluster')}
            </Button>
          </TutorialComponent>
        </Row>

        {/* TOP RIGHT */}
        <Row alignItems={'center'} columnGap={2}>
          <View style={styles.levelIndicator}>
            <LevelIndicator
              target={TargetType.Node}
              targetId={nodeId}
              flat={true}
              onEagleViewClick={() => setEagleViewOpen(!eagleViewOpen)}
              eagleViewOpen={eagleViewOpen}
            />
          </View>
          <TaskPendingIcon
            targetId={nodeId}
            targetType={MelddTaskTargetType.NODE_TASK}
            size={22}
          />
        </Row>
      </Row>
      <Row>
        <View style={{ flex: 1 }}>
          <MelddSearchBar
            icon={IconFilter}
            placeholder={t('search-clusters')}
            onChangeText={setSearchInput}
            onSearch={async (searchQuery) => {
              await refresh({ searchQuery })
              setSearchQuery(searchQuery)
            }}
            value={searchInput}
          />
        </View>
      </Row>

      <ScreenWrapper
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={() => refresh({ searchQuery })}
          />
        }
      >
        {loading && (
          <Row
            justifyContent={'center'}
            alignItems={'center'}
            style={{
              flex: 1,
              marginTop: theme.spacing(8),
            }}
          >
            <ActivityIndicator size={32} />
          </Row>
        )}
        {(data?.availableClusters || []).map((cluster: Cluster) => (
          <ClusterCard key={cluster.id} cluster={cluster} />
        ))}
        {data?.availableClusters?.length === 0 && (
          <View style={{ marginTop: theme.spacing(8) }}>
            <NoDataMessage nodeId={nodeId} searchQuery={searchQuery} />
          </View>
        )}
        <View style={{ height: theme.spacing(32) }}>{/* spacer */}</View>
      </ScreenWrapper>
      <EagleView
        targetId={nodeId}
        open={eagleViewOpen}
        onClose={() => setEagleViewOpen(false)}
      />
      {!eagleViewOpen && (
        <MButton
          targetId={nodeId}
          targetType={MelddTaskTargetType.NODE_TASK}
          preview={currentNode?.name || ''}
        />
      )}
      <Copyright />
      <ClusterListTutorial />
    </View>
  )
}
export default ClusterListScreen

const useStyle = createStyle(({ theme }) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingRight: 0,
    borderBottomColor: theme.colors.surfaceVariant,
    borderBottomWidth: 1,
  },
  levelIndicator: {
    // width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  info: {
    rowGap: theme.spacing(2),
    flex: 1,
  },
  topic: {},
  infoIcons: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  icon: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    width: theme.spacing(16),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(12),
    right: theme.spacing(10),
  },
}))
