import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native'
import {
  adaptNavigationTheme,
  MD3DarkTheme,
  MD3LightTheme,
} from 'react-native-paper'
import merge from 'deepmerge'
import melddTheme from './theme.json'

const melddLightColors = melddTheme.schemes.light
const melddDarkColors = melddTheme.schemes.dark
const melddLight = merge(MD3LightTheme, { colors: melddLightColors })
const melddDark = merge(MD3DarkTheme, { colors: melddDarkColors })
const palettes = melddTheme.palettes

const { LightTheme, DarkTheme } = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme,
  reactNavigationDark: NavigationDarkTheme,
  materialLight: melddLight,
  materialDark: melddDark,
})
const spaceFn = (factor: number) => factor * 4
const CustomSettings = {
  spacing: spaceFn,
  colors: {
    inquiry: '#6D90F7',
    challenge: '#CA3B7E',
    edit: '#F4B33E',
    owner_edit: '#ADD8E6',
    inquiryBkg: '#DDE5FD',
    challengeBkg: '#F7E3ED',
    editBkg: '#FDF2DD',
    reserved: '#7c7c7c',
    inquiry50: '#6D90F788',
    challenge50: '#CA3B7E88',
    edit50: '#F4B33E88',
    reserved50: '#7c7c7c88',
    owner_edit50: '#ADD8E688',

    inquiry30: 'rgba(92,123,208,0.53)',
    challenge30: 'rgba(180,52,113,0.53)',
    edit30: 'rgba(210,153,54,0.53)',
    reserved30: 'rgba(100,100,100,0.53)',

    pending: '#4259AA',
    accepted: '#008243',
    rejected: '#E00000',
    cancelled: '#7c7c7c',
    censured: '#7c7c7c',
    warn: '#ee6f57',
    danger: {
      border: '#FFD7D7',
      hover: '#A20000',
      main: '#E00000',
      pressed: '#630000',
      surface: '#FFECEC',
    },
    success: {
      border: '#B3DAC7',
      hover: '#006836',
      main: '#008243',
      pressed: '#005029',
      surface: '#EBF5F0',
    },
  },
  palettes,
  components: {
    pageContainer: {
      padding: spaceFn(4),
    },
  },
}

export const CombinedDarkTheme = merge(
  merge(melddDark, DarkTheme),
  CustomSettings
)
export const CombinedDefaultTheme = merge(
  merge(melddLight, LightTheme),
  CustomSettings
)
export type AppTheme = typeof CombinedDefaultTheme
import { useFeatures } from '../contexts/FeatureProvider'

// console.log('CombinedDefaultTheme', JSON.stringify(CombinedDefaultTheme, null, 2))
export const useTheme = (): AppTheme => {
  const { theme } = useFeatures()
  const returnTheme =
    theme === 'light' ? CombinedDefaultTheme : CombinedDarkTheme
  return returnTheme
}
export type ThemeType = typeof CombinedDefaultTheme | typeof CombinedDarkTheme
