import { View, ViewProps, ViewStyle } from 'react-native'
import { useTheme } from '../../hooks/use-theme'

interface RowProps extends ViewProps {
  children: React.ReactNode
  justifyContent?: ViewStyle['justifyContent']
  alignItems?: ViewStyle['alignItems']
  gap?: number
  columnGap?: number
  style?: ViewStyle
  flex?: number
  wrap?: boolean
}

export const Row: React.FC<RowProps> = ({
  children,
  justifyContent = 'flex-start',
  alignItems = 'center',
  columnGap = 0,
  gap = 0,
  flex,
  style,
  wrap,
  ...viewProps
}) => {
  const theme = useTheme()
  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          justifyContent,
          alignItems,
          gap: theme.spacing(gap || columnGap),
          columnGap: theme.spacing(columnGap),
          flex,
          flexWrap: wrap ? 'wrap' : 'nowrap',
        },
        style,
      ]}
      {...viewProps}
    >
      {children}
    </View>
  )
}

export default Row
