import * as React from 'react'
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native'
import { Surface, Text } from 'react-native-paper'
import {
  IconCluster,
  IconEagleView,
  IconNode,
  IconPerspective,
} from '../utils/meldd-icons'
import { Icon } from './Icon'
import { useTheme } from '../hooks/use-theme'
import { createStyle } from '../contexts/GlobalStylesProvider'
import { TargetType } from '../screens/PerspectiveCards/types'
import {
  useClusterByIdLazyQuery,
  useArticlePathLazyQuery,
} from '../generated/graphql'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../navigation/types'
import { TouchableOpacity } from 'react-native-gesture-handler'
import EagleView from './eagle-view/eagle-view'
import { TouchableIconWithToolTip } from '../utils/meldd-tooltip'
import { useTranslation } from 'react-i18next'

interface LevelIndicatorProps {
  target: TargetType
  targetId: string
  flat?: boolean
  style?: StyleProp<ViewStyle>
  onEagleViewClick?: () => void
  eagleViewOpen?: boolean
}

const ICONS = [IconNode, IconCluster, IconPerspective]
const ICON_SIZES = [18, 22, 12]
const LEVELS = [TargetType.Node, TargetType.Cluster, TargetType.Article]

function usePaginationIds({
  target,
  targetId,
}: {
  target: TargetType
  targetId: string
}) {
  const [getClusterById] = useClusterByIdLazyQuery()
  const [getArticleById] = useArticlePathLazyQuery()
  const [breadCrumpsIds, setBreadCrumpsIds] = React.useState<string[] | null>(
    null
  )

  const calls: Record<TargetType, () => Promise<string[] | null>> = {
    [TargetType.Node]: () => Promise.resolve([targetId]),
    [TargetType.Cluster]: async () => {
      const result = await getClusterById({ variables: { id: targetId } })
      if (!result.data) {
        return null
      }
      return [result.data.clusterById.nodeId, targetId]
    },
    [TargetType.Article]: async () => {
      const result = await getArticleById({ variables: { id: targetId } })
      if (!result.data) {
        return null
      }
      return [
        result.data.article.cluster.nodeId,
        result.data.article.cluster.id,
        targetId,
      ]
    },
    // TODO: Add when we have single contribution screen
    [TargetType.Contribution]: () => Promise.resolve(null),
  }

  React.useEffect(() => {
    calls[target]().then(setBreadCrumpsIds)
  }, [target, targetId])

  return breadCrumpsIds
}
export function LevelIndicator(props: LevelIndicatorProps) {
  const { target, flat, style } = props
  const styles = useStyle({ flat })
  const theme = useTheme()
  const level = LEVELS.indexOf(target)
  const paginationIds = usePaginationIds(props)
  const navigation = useNavigation<AppNavigation>()
  const { t } = useTranslation('level-indicator')

  const navigate = [
    () =>
      paginationIds &&
      navigation.push('ClusterList', { nodeId: paginationIds[0] }),
    () =>
      paginationIds &&
      navigation.push('ClusterCards', {
        nodeId: paginationIds[0],
        clusterId: paginationIds[1],
      }),
    () =>
      paginationIds &&
      navigation.push('PerspectiveCards', {
        articleId: paginationIds[2],
        clusterId: paginationIds[1],
      }),
  ]
  return (
    <Surface
      style={[styles.container, style]}
      mode={flat ? 'flat' : 'elevated'}
    >
      {LEVELS.slice(0, level + 1).map((_, i) => (
        <View style={styles.item} key={i}>
          {i === level && props.eagleViewOpen ? (
            <View style={{ paddingLeft: 2 }}>
              <TouchableIconWithToolTip
                mode="text"
                tooltip={t('eagle-view')}
                icon={<IconEagleView size={24} color="#C6A23F" />}
                onPress={props.onEagleViewClick}
              />
            </View>
          ) : (
            <TouchableIconWithToolTip
              mode="text"
              tooltip={t(`${LEVELS[i].toLowerCase()}`)}
              icon={
                <Icon
                  source={ICONS[i]}
                  size={ICON_SIZES[i]}
                  color={theme.colors.onBackground}
                />
              }
              onPress={() => {
                if (i === level) {
                  props.onEagleViewClick?.()
                } else {
                  navigate[i]()
                }
              }}
            />
          )}

          {i < level ? (
            <Text
              variant={'bodyLarge'}
              style={{
                color: theme.palettes.neutral[80]!,
                fontSize: 10,
                textAlign: 'center',
              }}
            >
              \
            </Text>
          ) : null}
        </View>
      ))}
    </Surface>
  )
}

const useStyle = ({ flat }: { flat: boolean | undefined }) =>
  createStyle(({ theme }) =>
    StyleSheet.create({
      container: {
        flexDirection: 'row',
        borderRadius: 10,
        paddingVertical: 6,
        paddingHorizontal: 14,
        columnGap: 5,
        alignItems: 'center',
        borderColor: theme.palettes.neutral[80]!,
        borderWidth: flat ? 1 : 0,
        backgroundColor: flat ? theme.colors.background : theme.colors.surface,
      },
      item: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        columnGap: 5,
        alignItems: 'center',
      },
    })
  )()
