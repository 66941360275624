import AsyncStorage from '@react-native-async-storage/async-storage'

export enum TutorialKey {
  CLUSTER_CARD = 'cluster_card',
  CLUSTER_SCREEN = 'cluster_screen',
  PERSPECTIVE_SCREEN = 'perspective_screen',
}

export class TutorialStorage {
  static async clearTutorialStorage() {
    return Promise.all(
      Object.values(TutorialKey).map(async (key) =>
        AsyncStorage.removeItem(`tutorial_${key}`)
      )
    )
  }

  static async setShown(tutorialKey: TutorialKey) {
    await AsyncStorage.setItem(`tutorial_${tutorialKey}`, 'true')
  }

  static async isAlreadyDisplayed(tutorialKey: TutorialKey) {
    const value = await AsyncStorage.getItem(`tutorial_${tutorialKey}`)
    return value === 'true'
  }
}
