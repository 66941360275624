import React, { createContext, useContext, useRef, useState } from 'react'
import { View } from 'react-native'

export type TutorialElement = {
  x: number
  y: number
  width: number
  height: number
}

type TutorialContextType = {
  registerElement: (
    id: string,
    element: TutorialElement
  ) => TutorialElement | undefined
  findElement: (id: string) => Promise<TutorialElement | undefined>
  getElement: (id: string) => TutorialElement | undefined
}

const TutorialContext = createContext<TutorialContextType>({
  registerElement: () => undefined,
  findElement: () => Promise.resolve(undefined),
  getElement: () => undefined,
})

export const TutorialProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const elements = useRef<Record<string, TutorialElement>>({})
  const registerElement = (id: string, element: TutorialElement) => {
    // Avoid overriding elements
    if (elements.current[id]) {
      return undefined
    }
    elements.current[id] = element
    return element
  }

  const getElement = (id: string) => {
    return elements.current[id]
  }

  const findElement = (
    id: string,
    attempts: number = 0
  ): Promise<TutorialElement | undefined> => {
    const pollInterval = 500 // 500ms between attempts
    const maxAttempts = 10

    // Is closing the tutorial
    if (!id) {
      return Promise.resolve(undefined)
    }

    const element = getElement(id)
    if (element) {
      return Promise.resolve(element)
    }

    attempts++
    if (attempts >= maxAttempts) {
      return Promise.resolve(undefined)
    }

    return new Promise((resolve) => {
      setTimeout(() => resolve(findElement(id, attempts + 1)), pollInterval)
    })
  }
  return (
    <TutorialContext.Provider
      value={{
        registerElement,
        findElement,
        getElement,
      }}
    >
      {children}
    </TutorialContext.Provider>
  )
}

export const useTutorial = () => useContext(TutorialContext)
