import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { Control, FieldErrors, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Button, TextInput } from 'react-native-paper'
import { ControlledTextInput } from '../../../components/form/ControlledTextInput'
import { HTMLErrorBox } from '../../../components/HTMLErrorBox'
import {
  ContributionStatus,
  ContributionType,
  SubmitContributionInput,
  useSubmitContributionMutation,
  useUpdateContributionStatusMutation,
} from '../../../generated/graphql'
import { useTheme } from '../../../hooks/use-theme'
import { Contribution } from '../types'
import { useValidationMessage } from './ValidationMessage'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import Toast from 'react-native-root-toast'
import { MessageType } from '../../../utils/message-type'
import * as Sentry from '@sentry/react-native'
import { useHeaderButton } from '../../../contexts/HeaderButtonProvider'
// New component outside the main function
const ContributionInputs = ({
  context,
  contribution,
  control,
  disabled,
  errors,
}: {
  context: string
  contribution: Contribution
  control: Control<FormShape>
  disabled?: boolean
  errors: FieldErrors<FormShape>
}) => {
  const { t } = useTranslation('contributions')
  const { intendedType } = contribution
  const input = useWatch({
    control,
    name: 'input',
  })

  if (
    [ContributionType.Edit, ContributionType.OwnerEdit].includes(
      intendedType as ContributionType
    )
  ) {
    const htmlValidationError = useValidationMessage(
      input,
      contribution,
      context
    )
    return (
      <>
        {control._getDirty('input') && (
          <HTMLErrorBox
            title={t('html-validation-error')}
            html={htmlValidationError}
          />
        )}
        <ControlledTextInput
          control={control}
          name={'input'}
          disabled={disabled}
          placeholder={t('Type your text')}
          rules={{
            validate: (value) => value !== contribution.originalText,
          }}
          label={t('Proposed Text')}
          error={!!errors.input}
          mode="outlined"
          autoCapitalize="none"
          helperText={errors.input?.message || t('Type your text')}
          multiline={true}
        />
        <ControlledTextInput
          control={control}
          name={'motivation'}
          disabled={disabled}
          placeholder={t('Type your justification')}
          label={t('Justification')}
          mode="outlined"
          autoCapitalize="none"
          helperText={t('Type your justification')}
          multiline={true}
        />
      </>
    )
  }

  if (intendedType === ContributionType.Challenge) {
    return (
      <>
        <ControlledTextInput
          control={control}
          name={'input'}
          disabled={disabled}
          placeholder={t('Type your challenge')}
          rules={{
            required: t('requiredChallenge'),
            validate: (value) => !!value.trim() || t('requiredChallenge'),
          }}
          label={t('Challenge')}
          error={!!errors.input}
          mode="outlined"
          autoCapitalize="none"
          helperText={errors.input?.message || t('Type your challenge')}
          multiline={true}
        />
        <ControlledTextInput
          control={control}
          name={'motivation'}
          disabled={disabled}
          placeholder={t('Provide a reference')}
          rules={{
            required: t('requiredReference'),
            validate: (value) => !!value.trim() || t('requiredReference'),
          }}
          label={t('Reference')}
          mode="outlined"
          error={!!errors.motivation}
          autoCapitalize="none"
          helperText={errors.motivation?.message || t('Provide a reference')}
          multiline={true}
        />
      </>
    )
  }

  if (intendedType === ContributionType.Inquiry) {
    return (
      <>
        {/* This is a hack to make the text not scroll too high on Android */}
        <TextInput
          style={{
            position: 'absolute',
            zIndex: -1,
            height: 1,
            width: 1,
            top: 20,
            left: 20,
          }}
          disabled={true}
        />
        <ControlledTextInput
          control={control}
          name={'input'}
          disabled={disabled}
          placeholder={t('Type your inquiry')}
          rules={{
            required: t('requiredInquiry'),
            validate: (value) => !!value.trim() || t('requiredInquiry'),
          }}
          label={t('Inquiry')}
          error={!!errors.input}
          mode="outlined"
          autoCapitalize="none"
          helperText={errors.input?.message || t('Type your inquiry')}
        />
      </>
    )
  }

  return null
}

interface ContributionInputProps {
  contribution: Contribution
  inputHandler: () => void | Promise<void>
  context: string
}

interface FormShape {
  input: string
  motivation: string
}

export function ContributionInput(props: ContributionInputProps) {
  const { contribution, context } = props
  /**
   * Basic Hooks and State
   */
  const theme = useTheme()
  const { t } = useTranslation('contributions')
  const style = useStyle()

  const [submitDisabled, setSubmitDisabled] = React.useState(false)

  /**
   * Api Actions
   */
  const [doSubmitContribution, { loading: saving }] =
    useSubmitContributionMutation()
  const [doUpdateContributionStatus] = useUpdateContributionStatusMutation()

  /**
   * Form State
   */
  const { control, watch, formState } = useForm<FormShape>({
    defaultValues: {
      input: [ContributionType.Edit, ContributionType.OwnerEdit].includes(
        contribution.intendedType as ContributionType
      )
        ? contribution.originalText
        : ' ',
      motivation: ' ',
    },
    mode: 'onTouched',
  })

  const { errors, isValid } = formState
  const [input, motivation] = watch(['input', 'motivation'])

  useEffect(() => {
    setSubmitDisabled(!isValid)
  }, [isValid])
  /**
   * Memos
   */
  const backgroundColor = useMemo(() => {
    const contributionTypeColors: Record<ContributionType, string> = {
      [ContributionType.Reserved]: theme.colors.editBkg,
      [ContributionType.Edit]: theme.colors.editBkg,
      [ContributionType.OwnerEdit]: theme.colors.editBkg,
      [ContributionType.Challenge]: theme.colors.challengeBkg,
      [ContributionType.Inquiry]: theme.colors.inquiryBkg,
    } as const

    if (!contribution.intendedType) {
      return undefined
    }
    return contributionTypeColors[contribution.intendedType] || undefined
  }, [theme, contribution.intendedType])

  /**
   * Cancel and submit actions are on the header.
   * When a contribution is selected or removed we update this header
   * and the callback functions associated with them
   */
  const { setHandler: setSaveHandler, setMeta } =
    useHeaderButton<Contribution>('saveContribution')
  const { setHandler: setCancelHandler, setDisabled: setCancelDisabled } =
    useHeaderButton<Contribution>('cancelContribution')

  const updateReservedMetaData = React.useCallback(
    (contribution: Contribution | null) => {
      if (!contribution) {
        setSaveHandler(null)
        setCancelHandler(null)
        setMeta(null)
        return
      }
      const cancelContribution = async () => {
        const input = {
          contributionId: contribution.id,
          status: ContributionStatus.Cancelled,
        }

        try {
          setCancelDisabled(true)
          await doUpdateContributionStatus({ variables: { input } })
        } catch (e) {
          Toast.show((e as Error).message, MessageType.error)
          console.error(e)
          Sentry.captureException(e)
        } finally {
          setSaveHandler(null)
          setCancelHandler(null)
          setMeta(null)
        }
      }

      setMeta(contribution)
      setSaveHandler(() => {
        //
      })
      setCancelHandler(cancelContribution)
      setCancelDisabled(!contribution)
    },
    [contribution]
  )

  useEffect(() => {
    updateReservedMetaData(contribution)
  }, [contribution])

  /**
   * Submit Action
   */
  const onSubmitContribution = React.useCallback(
    async (input: string, motivation: string) => {
      setSubmitDisabled(true)
      const reservedContribution = props.contribution

      const updateInput: SubmitContributionInput = {
        contributionId: reservedContribution.id,
        input:
          reservedContribution.intendedType == ContributionType.Edit
            ? input
            : input.trim(),
        motivation: motivation.trim(),
        type: reservedContribution.intendedType!,
      }

      try {
        await doSubmitContribution({
          variables: { input: updateInput },
        })

        props.inputHandler()

        Toast.show(t('The contribution has been made'), MessageType.info)
      } catch (e) {
        Toast.show((e as Error).message, MessageType.error)
        console.error(e)
        Sentry.captureException(e)
      }

      setSubmitDisabled(false)
    },
    [contribution, props.inputHandler]
  )

  return (
    <View style={[style.container, { backgroundColor }]}>
      <ContributionInputs
        control={control}
        errors={errors}
        context={context}
        contribution={contribution}
        disabled={saving}
      />
      <View style={style.submitButton}>
        <Button
          disabled={submitDisabled}
          mode={'contained'}
          onPress={() => onSubmitContribution(input, motivation)}
        >
          {t('Submit')}
        </Button>
      </View>
    </View>
  )
}

const useStyle = createStyle(({ theme }) => ({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: theme.spacing(5),
    marginTop: -theme.spacing(5),
    paddingHorizontal: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(5),
    borderTopRightRadius: theme.spacing(5),
  },
  submitButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))
