import { SelectionInfo } from '../../components/ArticleEditor/ArticleEditor'

// Converts ms to mm:ss format
const to2 = (num: number) => {
  return num < 10 ? `0${num}` : num
}
export const formatTime = (ms: number) => {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)

  const remainingMinutes = minutes % 60
  const remainingSeconds = seconds % 60

  const formattedTime = `${to2(remainingMinutes)}:${to2(remainingSeconds)}`
  return formattedTime
}
// Detect The left part of the tag `<strong>` will detect `trong>`
const TAG_START = /<[^>\n][\w\/]*$/
// Detect The right part of the tag `<strong>` will detect `<stro`
const TAG_END = /^[^<\n]*[\w\/]*>/

// Detect all the closing tags from this position
const LEADING_CLOSE_TAGS = /^(<\/[\w]+>)+/
const LEADING_START_TAGS = /(<[\w]+>)+$/

/**
 * Giving the text and a index, it returns
 * if the index is positioned inside a tag
 * Returning left, right and the whole tag
 */
const getTagOnIndex = (text: string, index: number) => {
  const left = text.slice(0, index)
  const right = text.slice(index)

  const leftMatch = left.match(TAG_START)
  const rightMatch = right.match(TAG_END)

  if (!leftMatch || !rightMatch) {
    return undefined
  }
  return {
    tag: `${leftMatch}${rightMatch}`,
    left: leftMatch[0],
    right: rightMatch[0],
  }
}

/**
 * When the user selects the format, we don't take in account that
 * the selection may cut a tag (editor issue).
 * Here we may sure that the selection include no cut tags on the sides
 * and all the ending tags for the users context.
 */
export const formatSelection = (text: string, selection: SelectionInfo) => {
  if (!selection.html) {
    return selection
  }
  // Catches the selection cutting a tag ` p>Hi` -> `<p> Hi`
  const startTag = getTagOnIndex(text, selection.start + 1) // On the start we need to do + 1
  if (startTag) {
    selection.html = startTag.left + selection.html
    selection.start -= startTag.left.length
  }
  // Catches the selection cutting a at the end `Hi</p` -> `Hi</p>`
  const endTag = getTagOnIndex(text, selection.end)
  if (endTag) {
    selection.html = selection.html + endTag.right
    selection.end += endTag.right.length
  }

  // Adding Leading tags  "<i></i><p><li>`Hi`" =>  "<i></i>`<p><li>Hi`" Will take starting tags until finish a end tag
  const leadingTags = text.slice(0, selection.start).match(LEADING_START_TAGS)
  if (leadingTags?.[0]) {
    selection.html = leadingTags[0] + selection.html
    selection.start -= leadingTags[0].length
  }

  // Add the consequent ending tags "`Hi`</em></p><p>"" -> `Hi</em></p>`
  const nextTags = text.slice(selection.end).match(LEADING_CLOSE_TAGS)
  if (nextTags?.[0]) {
    selection.html = selection.html + nextTags[0]
    selection.end += nextTags[0].length
  }

  return selection
}
