import { useCallback, useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { MelddSearchBar } from '../../components/form/MelddSearchBar'
import { IconButton, Text } from 'react-native-paper'
import { useSearchQuery } from '../../generated/graphql'
import { AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { useNode } from '../../contexts/CurrentNodeProvider'
import Row from '../../components/containers/Row'
import { SearchItem } from './search.item'
import { useFocusEffect } from '@react-navigation/native'

const PAGE_SIZE = 5

export function SearchScreen({
  route,
  navigation,
}: StackScreenProps<AppNavigatorParams, 'Search'>) {
  const { t } = useTranslation(['search'])
  const { nodeId } = route.params
  // Search Input Value
  const [searchText, setSearchText] = useState('')
  // Debounce Search Query
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const styles = useStyle()
  const { setCurrentNode } = useNode()

  const { data, loading, fetchMore } = useSearchQuery({
    variables: {
      input: {
        query: query,
        limit: PAGE_SIZE,
        offset: 0,
        nodeId: nodeId, // You'll need to provide the appropriate nodeId
      },
    },
    skip: !searchText || !nodeId,
  })

  useFocusEffect(
    useCallback(() => {
      setCurrentNode(route.params.nodeId)
      setSearchText(route.params.searchQuery || '')
      setQuery(route.params.searchQuery || '')
    }, [])
  )

  const handleSearch = async (text: string) => {
    setPage(1)
    setQuery(text)
  }

  const handleLoadMore = () => {
    if (loading) return

    fetchMore({
      variables: {
        input: {
          query: query,
          offset: page * PAGE_SIZE,
          limit: PAGE_SIZE,
          nodeId: nodeId,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          search: [...prev.search, ...fetchMoreResult.search],
        }
      },
    })
    setPage(page + 1)
  }

  return (
    <View style={styles.pageContainer}>
      <Row style={styles.searchBar}>
        <MelddSearchBar
          value={searchText}
          placeholder={t('search-placeholder')}
          onChangeText={setSearchText}
          onSearch={handleSearch}
        />
      </Row>
      <FlatList
        data={data?.search || []}
        renderItem={({ item }) => (
          <SearchItem item={item} searchTerm={searchText} />
        )}
        keyExtractor={(_, index) => index.toString()}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.5}
        style={styles.list}
        ListEmptyComponent={
          <View style={styles.emptyContainer}>
            <Text>{t(query ? 'search-no-results' : 'search-empty-query')}</Text>
          </View>
        }
      />
    </View>
  )
}

const useStyle = createStyle(({ theme }) => ({
  searchBar: {
    marginVertical: theme.spacing(2),
  },
  list: {
    flex: 1,
  },
  listItem: {
    padding: theme.spacing(2),
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.outline,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: 14,
    color: theme.colors.secondary,
  },
  emptyContainer: {
    paddingTop: theme.spacing(4),
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default SearchScreen
