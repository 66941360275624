import {
  View,
  StyleSheet,
  ScrollView,
  LayoutChangeEvent,
  Dimensions,
  Pressable,
} from 'react-native'
import {
  ContributionStatus,
  ContributionType,
} from '../../../generated/graphql'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ContributionColors } from '../../../constants/const'
import { ContributionPosition } from '../../../components/ArticleEditor/ArticleEditor'
import _ from 'lodash'
import { Article } from '../types'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { Contribution } from '../../Perspective/types'

interface ExtendedContribution extends Contribution {
  normalizedPosition: number
  height: number
  top: number
}
interface Props {
  contributions: Contribution[] // assuming you pass the number of contributions as a prop
  article: Article
  scrollPosition: {
    scrollTop: number
    scrollLeft: number
    viewportHeight: number
    contentHeight: number
  } | null
  positions: ContributionPosition[]
}

const IndicatorCircle = ({
  type,
  accepted,
}: {
  type: ContributionType
  accepted: boolean
}) => {
  return (
    <View
      style={[
        ...(accepted
          ? [
              styles.circle,
              {
                backgroundColor:
                  ContributionColors[type as keyof typeof ContributionColors] ||
                  ContributionColors.PENDING,
              },
            ]
          : [
              styles.filledCircle,
              {
                borderColor:
                  ContributionColors[type as keyof typeof ContributionColors] ||
                  ContributionColors.PENDING,
              },
            ]),
      ]}
    />
  )
}
const IndicatorComponent = function ({
  article,
  contributions,
  normalizedPosition,
}: {
  article: Article
  contributions: Array<ExtendedContribution>
  normalizedPosition: number
}) {
  const navigation = useNavigation<AppNavigation>()
  const types = useMemo(() => {
    const types = contributions.reduce((acc, contribution) => {
      acc[contribution.contributionType] =
        (acc[contribution.contributionType] || 0) + 1
      return acc
    }, {} as Record<ContributionType, number>)

    return types
  }, [contributions])

  const acceptedTypes = Object.keys(types).reduce((acc, type) => {
    if (
      contributions
        .filter((contribution) => contribution.contributionType === type)
        .some(
          (contributions) =>
            contributions.status === ContributionStatus.Accepted
        )
    ) {
      acc[type as ContributionType] = types[type as ContributionType]
    }
    return acc
  }, {} as Record<ContributionType, number>)

  const top = normalizedPosition + contributions[0]?.height / 2 - 28
  return Object.entries(types)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([type, count], index) => (
      <Pressable
        style={
          // Overlap circles if multiple types
          {
            top,
            position: 'absolute',
            left: index * 6, // slight offset for overlap
          }
        }
        key={`${normalizedPosition}-${index}`}
        onPress={() => {
          navigation.navigate('PerspectiveContributions', {
            contributionTypeFilter: type as ContributionType,
            articleId: article.id,
          })
        }}
      >
        <IndicatorCircle
          type={type as ContributionType}
          accepted={acceptedTypes[type as keyof typeof acceptedTypes] > 0}
        />
      </Pressable>
    ))
}
export function VerticalContributionsIndicator({
  contributions,
  scrollPosition,
  positions,
  article,
}: Props) {
  const scrollRef = useRef<ScrollView>(null)
  const [layoutPosition, setLayoutPosition] = useState<{
    top: number
    width: number
    left: number
    height: number
  }>()
  const windowHeight = Dimensions.get('window').height
  const positionGroups = useMemo((): _.Dictionary<ExtendedContribution[]> => {
    if (!positions.length) return {}

    const positionsMap = _.keyBy(positions, 'id')

    const contributionWithPositions: ExtendedContribution[] = contributions
      .map((contribution) => {
        if (!positionsMap[contribution.id]) {
          return undefined
        }

        const normalizedPosition = positionsMap[contribution.id].top

        // Group contributions by the approximated height each PIXEL_GROUP
        return {
          ...contribution,
          ...positionsMap[contribution.id],
          normalizedPosition: normalizedPosition,
        } as ExtendedContribution
      })
      .filter((contribution) => contribution !== undefined)
    const map = _.groupBy(contributionWithPositions, 'normalizedPosition')
    return map
  }, [contributions, positions])

  useEffect(() => {
    if (scrollRef.current && scrollPosition) {
      scrollRef.current.scrollTo({
        y: scrollPosition.scrollTop,
        animated: false,
      })
    }
  }, [scrollPosition])

  if (!scrollPosition) return null
  return (
    <View
      onLayout={(event: LayoutChangeEvent) => {
        const layout = event.nativeEvent.layout

        !layoutPosition && setLayoutPosition(layout as any) // LayoutRectangle has no top, but the event result has top information
      }}
      style={{
        height: windowHeight - (layoutPosition?.top || 0) - 74,
        overflow: 'hidden',
        paddingVertical: 16,
      }}
    >
      <ScrollView
        style={{ flexGrow: 1 }}
        scrollEnabled={false}
        contentContainerStyle={{}}
        ref={scrollRef}
      >
        <View
          style={[styles.container, { height: scrollPosition.contentHeight }]}
        >
          {Object.entries(positionGroups).map(
            ([normalizedPosition, group], index) => (
              <IndicatorComponent
                key={`${normalizedPosition}-${index}`}
                contributions={group}
                normalizedPosition={+normalizedPosition}
                article={article}
              />
            )
          )}
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 1,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4, // space between circles
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: 7,
    borderColor: 'white',
    borderWidth: 0.5,
    marginVertical: 2,
  },
  filledCircle: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: 'white',
    borderWidth: 1.5,
    marginVertical: 2,
  },
})
