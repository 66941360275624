import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Icon, Text } from 'react-native-paper'
import { Checkbox } from 'react-native-paper'
import Row from '../../components/containers/Row'
import { TouchableIconWithToolTip } from '../../utils/meldd-tooltip'
import llmModelsData from './llm-models.json'

export const LLMList = ({
  llmModels,
  setLlmModels,
}: {
  llmModels: string[]
  setLlmModels: (callback: (prev: string[]) => string[]) => void
}) => {
  const { t } = useTranslation('articles')

  const [toggle, setToggle] = useState(false)
  return (
    <>
      <Row justifyContent="space-between" alignItems="center">
        <Text>
          {t('llm-selected')} {llmModels.join(', ')}
        </Text>
        <TouchableIconWithToolTip
          icon={
            <Icon source={toggle ? 'chevron-up' : 'chevron-down'} size={24} />
          }
          tooltip={t('select-model')}
          onPress={() => setToggle(!toggle)}
        />
      </Row>
      {toggle && (
        <LLMCheckbox llmModels={llmModels} setLlmModels={setLlmModels} />
      )}
    </>
  )
}
const LLMCheckbox = ({
  llmModels,
  setLlmModels,
}: {
  llmModels: string[]
  setLlmModels: (callback: (prev: string[]) => string[]) => void
}) => {
  const toggleCheckbox = (model: string) => {
    // In the future we can generate several models at the same time
    // setLlmModels((prev) =>
    //   prev.includes(model) ? prev.filter((m) => m !== model) : [...prev, model]
    // )
    setLlmModels((prev) => [model])
  }

  const styles = StyleSheet.create({
    column: {
      flex: 1,
      padding: 10,
      alignItems: 'flex-start',
      minWidth: 300,
    },
    llmContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flex: 1,
      width: '100%',
      paddingBottom: 10,
    },
    todoMessage: {
      color: 'grey',
      fontStyle: 'italic',
      fontSize: 12,
      marginTop: 5,
    },
    llmTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 10,
    },
  })

  return (
    <View style={styles.llmContainer}>
      {Object.entries(llmModelsData)
        .filter(([_, data]) => data.enabled)
        .map(([category, data]) => (
          <View key={category} style={styles.column}>
            <Text style={styles.llmTitle}>{data.title}</Text>
            {data.models.map((model) => (
              <Checkbox.Item
                key={model.id}
                labelStyle={{ flex: 1 }}
                label={model.label}
                disabled={model.disabled}
                status={llmModels.includes(model.id) ? 'checked' : 'unchecked'}
                onPress={() => toggleCheckbox(model.id)}
              />
            ))}
          </View>
        ))}
    </View>
  )
}
