import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack'
import {
  MelddNavigationBar,
  MelddNavigationBarProps,
} from '../components/MelddNavigationBar'
import {
  NodeNavigationBar,
  NodeNavigationBarProps,
} from '../components/NodeNavigationBar'
import { AcceptInvitationScreen } from '../screens/AcceptInvitation'
import ClusterCardsScreen from '../screens/ClusterCards/ClusterCardsScreen'
import { ClusterListScreen } from '../screens/ClusterList/ClusterListScreen'
import { LoginMobileNumberScreen } from '../screens/Login/LoginMobileNumber'
import {
  PerspectiveNavigationBar,
  PerspectiveNavigationBarProps,
} from '../screens/Perspective/components/PerspectiveNavigationBar'
import PerspectiveScreen from '../screens/Perspective/PerspectiveScreen'
import { PrivacyPolicyScreen } from '../screens/PrivacyPolicy'
import {
  ProfileNavigationBar,
  ProfileNavigationBarProps,
} from '../screens/Profile/components/ProfileNavigationBar'
import { RegisterScreen } from '../screens/Register'
import WechatRedirect from '../screens/Social/WechatRedirect'
import { VisualizationNavigationBar } from '../screens/Visualization/VisualizationNavigationBar'
import { AppNavigatorParams } from './types'

/**
 * Lazy loads can not be access directly via URL on the web version.
 * For that do a normal import.
 */
import {
  LoginNavigationBar,
  RegisterNavigationBar,
} from '../components/LoginNavigationBar'
import ClusterSettingsScreen from '../screens/Cluster/ClusterSettingsScreen'
import CreateClusterScreen from '../screens/Cluster/CreateClusterScreen'
import ClusterContributionsScreen from '../screens/Contributions/ClusterContributionsScreen'
import DecisionsScreen from '../screens/Contributions/DecisionsScreen'
import PerspectiveContributionScreen from '../screens/Contributions/PerspectiveContributionScreen'
import PerspectiveContributionsScreen from '../screens/Contributions/PerspectiveContributionsScreen'
import UserContributionsScreen from '../screens/Contributions/UserContributionsScreen'
import CreateArticleScreen from '../screens/CreateArticle/CreateArticle'
import FeatureTogglesScreen from '../screens/FeatureToggleScreen'
import IntroScreen from '../screens/Intro'
import ListNodesScreen from '../screens/ListNodes/ListNodesScreen'
import NodeSettingsScreen from '../screens/NodeSettings/NodeSettingsScreen'
import NodeStatsScreen from '../screens/NodeStats/NodeStatsScreen'
import PerspectiveCardsScreen from '../screens/PerspectiveCards/PerspectiveCardsScreen'
import ProfileScreen from '../screens/Profile/ProfileScreen'
import ProfileVerifyMobileScreen from '../screens/Profile/ProfileVerifyMobileScreen'
import MyTasksScreen from '../screens/tasks/my-tasks.screen'
import TaskDetail from '../screens/tasks/task-detail.screen'
import { TaskListScreen } from '../screens/tasks/task-list.screen'
import UpdateArticleScreen from '../screens/UpdateArticle'
import VisualizationScreen from '../screens/Visualization/VisualizationScreen'
import { TutorialProvider } from '../components/Tutorial/TutorialContext'
import { SearchScreen } from '../screens/search/search.screen'
import { useAuthContext } from '../auth/auth-context'

const Stack = createStackNavigator<AppNavigatorParams>()
// We require export the names for web3, on reload a page they need to match URI with screen
export const AppNavigatorScreens = {
  WechatRedirect: 'WechatRedirect',
  Visualization: 'Visualization',
  ListNodes: 'ListNodes',
  ClusterList: 'ClusterList',
  ClusterCards: 'ClusterCards',
  ClusterContributions: 'ClusterContributions',
  Perspective: 'Perspective',
  PerspectiveCards: 'PerspectiveCards',
  PerspectiveContributions: 'PerspectiveContributions',
  PerspectiveContribution: 'PerspectiveContribution',
  CreateArticle: 'CreateArticle',
  UpdateArticle: 'UpdateArticle',
  CreateCluster: 'CreateCluster',
  ClusterSettings: 'ClusterSettings',
  NodeSettings: 'NodeSettings',
  NodeStats: 'NodeStats',
  ProfileVerifyMobile: 'ProfileVerifyMobile',
  Profile: 'Profile',
  Decisions: 'Decisions',
  FeatureToggles: 'FeatureToggles',
  Intro: 'Intro',
  UserContributions: 'UserContributions',
  LoginMobileNumber: 'LoginMobileNumber',
  Register: 'Register',
  PrivacyPolicy: 'PrivacyPolicy',
  MyTasks: 'MyTasks',
  Task: 'task/:taskId?',
  TaskList: 'task-list/:targetType/:targetId',
  Search: 'Search',
}

const AppNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props: StackHeaderProps) => (
          <MelddNavigationBar {...(props as MelddNavigationBarProps)} />
        ),
      }}
    >
      <Stack.Screen
        name="WechatRedirect"
        component={WechatRedirect}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen
        name="Visualization"
        component={VisualizationScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <VisualizationNavigationBar
              {...(props as NodeNavigationBarProps)}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ListNodes"
        component={ListNodesScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <MelddNavigationBar
              {...(props as MelddNavigationBarProps)}
              back={{ title: '' }}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ClusterList"
        component={(props: any) => (
          <TutorialProvider>
            <ClusterListScreen {...props} />
          </TutorialProvider>
        )}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen
        name="ClusterCards"
        component={(props: any) => (
          <TutorialProvider>
            <ClusterCardsScreen {...props} />
          </TutorialProvider>
        )}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen
        name="ClusterContributions"
        component={ClusterContributionsScreen}
      />
      <Stack.Screen
        name="Perspective"
        component={(props: any) => (
          <TutorialProvider>
            <PerspectiveScreen {...props} />
          </TutorialProvider>
        )}
        options={{
          header: (props: StackHeaderProps) => (
            <PerspectiveNavigationBar
              {...(props as PerspectiveNavigationBarProps)}
            />
          ),
        }}
      />
      <Stack.Screen
        name="PerspectiveCards"
        component={PerspectiveCardsScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <NodeNavigationBar {...(props as NodeNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen
        name="PerspectiveContributions"
        component={PerspectiveContributionsScreen}
      />
      <Stack.Screen
        name="PerspectiveContribution"
        component={PerspectiveContributionScreen}
      />
      <Stack.Screen name="CreateArticle" component={CreateArticleScreen} />
      <Stack.Screen name="UpdateArticle" component={UpdateArticleScreen} />
      <Stack.Screen name="CreateCluster" component={CreateClusterScreen} />
      <Stack.Screen name="ClusterSettings" component={ClusterSettingsScreen} />
      <Stack.Screen name="NodeSettings" component={NodeSettingsScreen} />
      <Stack.Screen name="NodeStats" component={NodeStatsScreen} />
      <Stack.Screen
        name="ProfileVerifyMobile"
        component={ProfileVerifyMobileScreen}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          header: (props: StackHeaderProps) => {
            const { userId } = (props as ProfileNavigationBarProps).route.params
            const { userId: loggedInUserId } = useAuthContext()
            if (!userId || userId !== loggedInUserId) {
              return (
                <MelddNavigationBar {...(props as MelddNavigationBarProps)} />
              )
            }
            return (
              <ProfileNavigationBar {...(props as ProfileNavigationBarProps)} />
            )
          },
        }}
      />
      <Stack.Screen name="Decisions" component={DecisionsScreen} />
      <Stack.Screen name="FeatureToggles" component={FeatureTogglesScreen} />
      <Stack.Screen name="Intro" component={IntroScreen} />
      <Stack.Screen
        name="UserContributions"
        component={UserContributionsScreen}
      />
      <Stack.Screen
        name={'AcceptInvitation'}
        component={AcceptInvitationScreen}
      />
      <Stack.Screen
        name="LoginMobileNumber"
        component={LoginMobileNumberScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <LoginNavigationBar {...(props as MelddNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen
        name="Register"
        component={RegisterScreen}
        options={{
          header: (props: StackHeaderProps) => (
            <RegisterNavigationBar {...(props as MelddNavigationBarProps)} />
          ),
        }}
      />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
      <Stack.Screen name="Task" component={TaskDetail} />
      <Stack.Screen name="MyTasks" component={MyTasksScreen} />
      <Stack.Screen name="TaskList" component={TaskListScreen} />
      <Stack.Screen name="Search" component={SearchScreen} />
    </Stack.Navigator>
  )
}

export default AppNavigator
