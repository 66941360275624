import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, StyleSheet, View } from 'react-native'
import 'react-native-get-random-values'
import { Text } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import { useAuthContext } from '../../auth/auth-context'
import {
  ArticleEditor,
  ArticleEditorRef,
  ContributionPosition,
  SelectionInfo,
} from '../../components/ArticleEditor/ArticleEditor'
import Row from '../../components/containers/Row'
import EagleView from '../../components/eagle-view/eagle-view'
import MButton from '../../components/m-button/MButton'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useHeaderButton } from '../../contexts/HeaderButtonProvider'
import {
  ArticleQuery,
  ContributionStatus,
  ContributionType,
  useArticleQuery,
} from '../../generated/graphql'
import { useTheme } from '../../hooks/use-theme'
import { useWechatShareInit } from '../../hooks/use-wechat-share'
import { AppNavigatorParams, AppNavigation } from '../../navigation/types'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'
import { ERROR_CODES, hasApolloErrorCode } from '../../utils/error-utils'
import { MessageType } from '../../utils/message-type'
import { MelddTaskTargetType } from '../tasks/task.types'
import { formatSelection } from './format'
import { Article, Contribution } from './types'
import { Tutorial } from '../../components/Tutorial/Tutorial'
import { TutorialKey } from '../../components/Tutorial/TutorialStorage'
import { VerticalContributionsIndicator } from './components/VerticalContributionsIndicator'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { BottomBar } from './components/BottomBar'
import {
  CreateTaskModal,
  CreateTaskModalProps,
} from '../../components/m-button/CreateTaskModal'

export type ArticleDetails = ArticleQuery['article']

const CONTRIBUTION_TYPE_MAP = {
  [ContributionType.Challenge]: 3,
  [ContributionType.Edit]: 1,
  [ContributionType.Inquiry]: 2,
  [ContributionType.Reserved]: 0,
  [ContributionType.OwnerEdit]: 4,
} as const

const PerspectiveContributionTutorial = () => {
  const { t } = useTranslation('tutorials')
  const steps = useMemo(
    () => [{ tutorialId: 'article_editor', message: t('add_contribution') }],
    [t]
  )

  return <Tutorial steps={steps} tutorialKey={TutorialKey.PERSPECTIVE_SCREEN} />
}

function PerspectiveScreenImplementation({ articleId }: { articleId: string }) {
  /**
   * Basics hooks
   */
  const editorRef = useRef<ArticleEditorRef>(null)
  const navigation = useNavigation<AppNavigation>()
  const { userId, isAnonymous, openLoginModal, authAction } = useAuthContext()
  const theme = useTheme()
  const { t } = useTranslation('articles')
  const isFocused = useIsFocused()
  const styles = useStyles()
  /**
   * Refs
   */
  const createTaskModalRef = useRef<CreateTaskModalProps>(null)

  /**
   * To Control the Header menus
   */
  const { setHandler: setCreateTaskModalAction } =
    useHeaderButton<boolean>('createTask')
  const { meta: visible } = useHeaderButton<boolean>('visibleButton')
  const { setMeta: setMetaPerspective } =
    useHeaderButton<Article>('perspective')
  const { meta: eagleViewOpen, setMeta: setEagleViewOpen } =
    useHeaderButton<boolean>('eagleView')
  const [reservedContribution, _setReservedContribution] =
    useState<Contribution | null>(null)
  const { setHandler: setBackButton } = useHeaderButton('navigation')

  /**
   * State of the screen
   */
  const [selection, setSelection] = useState({ start: 0, end: 0, html: '' })
  const [editorContributionId, setEditorContributionId] = useState<string>('')
  const [perspective, setPerspective] = useState<Article | null>(null)
  const [contributionPositions, setContributionPositions] = useState<
    ContributionPosition[]
  >([])
  const [scrollPosition, setScrollPosition] = useState<{
    scrollTop: number
    scrollLeft: number
    viewportHeight: number
    contentHeight: number
  } | null>(null)

  const {
    data,
    refetch: refetchCore,
    loading,
    error: articleError,
  } = useArticleQuery({
    variables: { id: articleId },
    fetchPolicy: 'cache-first',
  })

  const refetch = useCallback(async () => {
    await refetchCore()
  }, [refetchCore])

  useSocketRoom(articleId).event(SocketEvents.Refresh, refetch)

  const contributions: Contribution[] = useMemo(() => {
    return data?.article.contributions || []
  }, [data?.article.contributions])

  useEffect(() => {
    if (!articleError) {
      return
    }

    // For some scenarios request login
    if (
      hasApolloErrorCode(articleError, [
        ERROR_CODES.METHOD_NOT_ALLOWED,
        ERROR_CODES.FORBIDDEN,
      ]) &&
      isAnonymous
    ) {
      openLoginModal(navigation)
      return
    }
    // Display the error
    Toast.show(
      t(articleError?.message || "We couldn't find your article"),
      MessageType.error
    )
  }, [articleError])

  const editorContributions = useMemo(
    () =>
      contributions.map((contribution) => ({
        status: contribution.status,
        id: contribution.id,
        start: contribution.begin,
        end: contribution.end,
        type: CONTRIBUTION_TYPE_MAP[contribution.contributionType] ?? 0,
      })),
    [contributions]
  )

  useEffect(() => {
    if (!perspective) {
      return
    }
    editorRef.current?.setEditorContent(perspective?.latestContent || '')
    editorRef.current?.setContributions(editorContributions)
    editorRef.current?.showContributions(true)

    setCreateTaskModalAction(() =>
      authAction(navigation, () => {
        createTaskModalRef.current?.open(
          perspective.id,
          MelddTaskTargetType.ARTICLE_TASK,
          perspective.title
        )
      })
    )
  }, [perspective, editorContributions])

  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])

  useEffect(() => {
    if (!data?.article) {
      return
    }
    setPerspective(data.article)
    setMetaPerspective(data.article)
  }, [data?.article])

  useEffect(() => {
    if (!perspective?.clusterId) {
      return
    }
    setBackButton(() => {
      navigation.push('ClusterCards', {
        clusterId: perspective.clusterId,
        nodeId: perspective.cluster.nodeId,
      })
    })
  }, [perspective?.clusterId])

  const onSelection = (selectionInfo: SelectionInfo) => {
    if (!perspective) {
      return
    }
    if (selectionInfo.end === 0) {
      setSelection(selectionInfo)
      return
    }
    if (selectionInfo.end - selectionInfo.start < 1) {
      setSelection(selectionInfo)
      return
    }

    /**
     * TODO: This format is better if is done in the editor it self
     */
    const selection = formatSelection(perspective.latestContent, {
      ...selectionInfo,
    })
    setSelection(selection)
  }

  useEffect(() => {
    if (!editorRef.current || !contributions?.length) {
      return
    }

    // IF I have an active contribution
    const reservedContribution = contributions.find(
      (c) =>
        c.userId == userId &&
        c.contributionType === ContributionType.Reserved &&
        new Date(c.reservedUntil).getTime() > Date.now()
    )

    if (reservedContribution) {
      setSelection({
        start: reservedContribution.begin,
        end: reservedContribution.end,
        html: reservedContribution.originalText,
      })
      editorRef.current?.setSelectionFromContribution({
        id: reservedContribution.id,
        start: reservedContribution.begin,
        end: reservedContribution.end,
        type: 0,
      })
      _setReservedContribution(reservedContribution)
      return
    }

    _setReservedContribution(null)
    setSelection({ start: 0, end: 0, html: '' })
    editorRef.current?.lockSelection(false)
  }, [contributions, editorContributions, _setReservedContribution])

  useWechatShareInit(() => {
    if (!perspective) return null
    return {
      title: perspective.title,
      description: `${perspective.contributions.length} contribution(s) and ${perspective.voteCount} vote(s)`,
    }
  }, [perspective])

  const onContribution = (contributionId: string | null) => {
    if (!contributionId) {
      setEditorContributionId('')
      return
    }

    const contribution = contributions.find((c) => c.id === contributionId)
    if (!contribution) {
      return
    }

    setEditorContributionId(contribution.id)
  }
  return (
    <ScreenWrapper withScrollView={false} style={styles.container}>
      <>
        {perspective && !reservedContribution && (
          <Row
            style={{
              paddingHorizontal: 24,
              paddingTop: 8,
            }}
          >
            <Text variant={'headlineSmall'}>{perspective.title}</Text>
          </Row>
        )}
      </>

      <Row style={{ alignItems: 'stretch', flex: 1 }}>
        <View
          style={{
            flexDirection: 'column',
            display: 'flex',
            flex: 1,
          }}
        >
          <ArticleEditor
            key={articleId}
            style={styles.editor}
            ref={editorRef}
            editorMode={'select'}
            onRefresh={refetch}
            onSelection={onSelection}
            onScrollEnd={setScrollPosition}
            onContribution={onContribution}
            onContributionPositions={setContributionPositions}
          />
        </View>
        {visible && (
          <View
            style={{
              flexDirection: 'column',
              display: 'flex',
              width: 20,
              marginHorizontal: theme.spacing(2),
            }}
          >
            <VerticalContributionsIndicator
              article={perspective as Article}
              scrollPosition={scrollPosition}
              contributions={
                (perspective?.contributions as Contribution[]) || []
              }
              positions={contributionPositions}
            />
          </View>
        )}
      </Row>
      <>
        {/*
          Normally this is only access through MButton, however in this screen
          we open the modal from the menu, that doesn't allow appollo component
          (cos a bug that I don't understand) on <header>subcomponents</header>
        */}
        {perspective && <CreateTaskModal ref={createTaskModalRef} />}
        {perspective && (
          <EagleView
            targetId={perspective.id}
            open={eagleViewOpen && !editorContributionId}
            onClose={() => setEagleViewOpen(false)}
          />
        )}
        {perspective && (
          <BottomBar
            perspective={perspective}
            reservedContribution={reservedContribution}
            editorContributionId={editorContributionId}
            onReservedContribution={() => {
              _setReservedContribution(null)
              editorRef.current?.lockSelection(false)
              editorRef.current?.clearSelection()
              setSelection({ start: 0, end: 0, html: '' })
              refetch()
            }}
            selection={selection}
            editorRef={editorRef}
            selectContributionChange={(value) => {
              // Null means close modal
              if (value === null) {
                setEditorContributionId('')
                return
              }
              editorRef.current?.selectContributionChange(value)
            }}
          />
        )}
        <PerspectiveContributionTutorial />
      </>
    </ScreenWrapper>
  )
}

export default function PerspectiveScreen({
  route,
}: StackScreenProps<AppNavigatorParams, 'Perspective'>) {
  const { articleId } = route.params
  /*
   * React Native reuses the page component,
   * causing a cache effect and displaying previous article content.
   *
   * This is a workaround to force a new component to be created when the articleId changes.
   */
  return (
    <PerspectiveScreenImplementation key={articleId} articleId={articleId} />
  )
}

const useStyles = createStyle(({ theme }) =>
  StyleSheet.create({
    container: {
      flexDirection: 'column',
      display: 'flex',
      backgroundColor: 'white',
      alignItems: 'stretch',
      overflow: 'hidden',
      height: Dimensions.get('window').height - 64,
    },
    editor: {
      flex: 1,
      flexShrink: 1,
      backgroundColor: 'white',
      paddingHorizontal: theme.spacing(2),
    },
    info: {
      paddingVertical: theme.spacing(4),
      paddingHorizontal: theme.spacing(3),
    },
  })
)
