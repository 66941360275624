import { StackScreenProps } from '@react-navigation/stack'
import { orderBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconButton } from 'react-native-paper'
import { useAuthContext } from '../../auth/auth-context'
import Row from '../../components/containers/Row'
import { LevelIndicator } from '../../components/LevelIndicator'
import MButton from '../../components/m-button/MButton'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { SwipeableCards } from '../../components/SwipeableCards'
import { useNode } from '../../contexts/CurrentNodeProvider'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { useHeaderButton } from '../../contexts/HeaderButtonProvider'
import {
  useAvailableClustersLazyQuery,
  useClusterByIdLazyQuery,
} from '../../generated/graphql'
import { useTheme } from '../../hooks/use-theme'
import { useWechatShareInit } from '../../hooks/use-wechat-share'
import { AppNavigatorParams } from '../../navigation/types'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'
import { TargetType } from '../PerspectiveCards/types'
import { TaskPendingIcon } from '../tasks/components/task-pending-icon'
import { MelddTaskTargetType } from '../tasks/task.types'
import { ClusterCard } from './components/ClusterCard'
import EagleView from '../../components/eagle-view/eagle-view'
import { useTranslation } from 'react-i18next'
import { TutorialStep } from '../../components/Tutorial/Tutorial'
import { Tutorial } from '../../components/Tutorial/Tutorial'
import { TutorialKey } from '../../components/Tutorial/TutorialStorage'
import { useFocusEffect } from '@react-navigation/native'

export function ClusterCardsScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'ClusterCards'>) {
  const { params } = route
  const clusterId = params?.clusterId
  const nodeId = params?.nodeId
  const [eagleViewOpen, setEagleViewOpen] = useState(false)
  const globalStyles = useGlobalStyles()
  const { setHandler } = useHeaderButton('visualizationCluster')
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)
  const theme = useTheme()
  // Is a function provide for the swipper to move to a card programmatically
  const moveToCard = useRef<{ (card: number): void } | undefined>(undefined)
  const [doLoadClusters, { data, loading }] = useAvailableClustersLazyQuery()
  const [doLoadCluster] = useClusterByIdLazyQuery()
  const { setCurrentNode } = useNode()

  const clusters = useMemo(
    () =>
      orderBy(data?.availableClusters || [], ['contentUpdatedAt'], ['desc']),
    [data]
  )

  useEffect(() => {
    async function loadNode() {
      if (nodeId) {
        setCurrentNode(nodeId)
        return
      }
      // If node ID is not set (for example when coming from task)
      // We try to load the cluster and get the node ID from the cluster
      if (clusterId) {
        const { data } = await doLoadCluster({ variables: { id: clusterId } })

        if (data?.clusterById?.nodeId) {
          navigation.replace('ClusterCards', {
            clusterId: clusterId,
            nodeId: data?.clusterById?.nodeId,
          })
          return
        }
      }
      // If all fails go to List Nodes
      navigation.replace('ListNodes')
    }
    loadNode()
  }, [nodeId])

  useEffect(() => {
    return () => setHandler(null)
  }, [])

  const doRefresh = useCallback(async () => {
    if (!nodeId) {
      return
    }
    const res = await doLoadClusters({
      variables: { input: { nodeId } },
      fetchPolicy: 'network-only',
    })
  }, [nodeId])

  // Reload every time the screen is focused (avoid cached issues adding or removing clusters)
  useFocusEffect(
    useCallback(() => {
      doRefresh()
    }, [])
  )

  useSocketRoom(nodeId).event(SocketEvents.Refresh, doRefresh)

  const clusterCards = useMemo(() => {
    return clusters.map((c) => (
      <ClusterCard
        key={c.id}
        cluster={c}
        onRefresh={doRefresh}
        refreshing={loading}
      />
    ))
  }, [clusters, loading, doRefresh])

  const selectedIndex = useMemo(() => {
    const v = clusters.findIndex((c) => c.id === clusterId)
    if (v < 0 && clusters.length > 0) {
      return 0
    }
    return v
  }, [clusters, clusterId])

  useEffect(() => {
    doRefresh()
  }, [nodeId])

  const updateVisualizationMenu = useCallback(
    (ix: number) => {
      const clusterId = clusters[ix]?.id || clusters[0]?.id
      if (clusterId) {
        setHandler(() => navigation.navigate('Visualization', { clusterId }))
      } else {
        setHandler(null)
      }
      navigation.setParams({
        clusterId,
        nodeId: params.nodeId,
        external: undefined,
      })
    },
    [clusters]
  )

  // If the data changes (some cluster updated), make sure the user keeps seeing the right cluster when they re-order
  useEffect(() => {
    if (selectedIndex < 0) {
      return
    }
    moveToCard.current?.(selectedIndex)
  }, [data])

  useWechatShareInit(() => {
    if (!clusters[selectedClusterIndex]) return null
    const currentCluster = clusters[selectedClusterIndex]
    return {
      title: currentCluster.topic,
      description: `${currentCluster.articles.length} perspective(s) and ${currentCluster.articleVotes.length} vote(s)`,
    }
  }, [clusters, selectedClusterIndex])

  return (
    <ScreenWrapper withScrollView={false} style={globalStyles.pageContainer}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: theme.spacing(4) }}
      >
        <IconButton
          mode={'outlined'}
          icon="arrow-left"
          onPress={() => navigation.push('ClusterList', { nodeId })}
        />
      </Row>
      {clusterCards.length > 0 && (
        <SwipeableCards
          initialSelectedIndex={selectedIndex}
          onSelectedIxChange={(index) => {
            updateVisualizationMenu(index)
            setSelectedClusterIndex(index)
          }}
          moveToCardAction={(action) => (moveToCard.current = action)}
        >
          {clusterCards}
        </SwipeableCards>
      )}
      <EagleView
        targetId={clusters[selectedClusterIndex]?.id}
        open={eagleViewOpen}
        onClose={() => setEagleViewOpen(false)}
      />
      {clusters[selectedClusterIndex] && !eagleViewOpen && (
        <MButton
          targetType={MelddTaskTargetType.CLUSTER_TASK}
          targetId={clusters[selectedClusterIndex]?.id}
          preview={clusters[selectedClusterIndex]?.topic}
        />
      )}
      <ClusterCardTutorial />
    </ScreenWrapper>
  )
}

function ClusterCardTutorial() {
  const { t } = useTranslation('tutorials')

  const tutorialSteps: TutorialStep[] = [
    {
      tutorialId: 'create_perspective',
      message: t('create_perspective'),
    },
    {
      tutorialId: 'vote_perspective',
      message: t('vote_perspective'),
    },
  ]

  return (
    <Tutorial steps={tutorialSteps} tutorialKey={TutorialKey.CLUSTER_CARD} />
  )
}
export default ClusterCardsScreen
