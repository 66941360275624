import { StackScreenProps } from '@react-navigation/stack'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TextInput, View } from 'react-native'
import Toast from 'react-native-root-toast'
import {
  ArticleEditor,
  ArticleEditorRef,
} from '../../components/ArticleEditor/ArticleEditor'
import MelddButton from '../../components/MelddButton'
import {
  ArticleDetailFragmentDoc,
  Cluster,
  CreateArticleInput,
  useClusterByIdQuery,
  useCreateArticleMutation,
} from '../../generated/graphql'
import { useKeyboard } from '../../hooks/use-keyboard-hook'
import { AppNavigatorParams } from '../../navigation/types'
import { MessageType } from '../../utils/message-type'
import { Theme } from '../../utils/theme'
import { isDesktopBrowser } from '../../utils/wechat.login'
import { useNavigation, useRoute } from '@react-navigation/native'
import {
  IconAIPerspective,
  IconManualPerspective,
} from '../../utils/meldd-icons'
import MelddTabs from '../../components/containers/MelddTabs'
import Row from '../../components/containers/Row'
import LoadingFullScreen from '../../components/containers/LoadingFullScreen'
import { LLMList } from './LLMList'
import { Text } from 'react-native-paper'
import {
  DEFAULT_MODEL,
  getDefaultPrompt,
  LLMProvider,
  useLLMState,
} from './LLMProvider'
import i18n from '../../i18n'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import * as Sentry from '@sentry/react-native'
import { ApolloError } from '@apollo/client'
import { getApolloErrorCode } from '../../utils/error-utils'

const trimProcessor = (content: string) => content.trim()
const convertLinksProcessor = function processOrphanLinks(
  content: string
): string {
  // First, temporarily replace existing <a> tags with a placeholder
  const placeholders: { [key: string]: string } = {}
  let counter = 0

  const contentWithoutATags = content.replace(
    /<a\s+[^>]*>.*?<\/a>/g,
    (match) => {
      const placeholder = `__LINK_PLACEHOLDER_${counter}__`
      placeholders[placeholder] = match
      counter++
      return placeholder
    }
  )

  // Find all URLs and create placeholders for them
  const urlRegex = /https?:\/\/[^\s<>,()[\]{}'"]+\b/g

  const contentWithNewATags = contentWithoutATags.replace(urlRegex, (url) => {
    return `<a href="${url}" title="${url}">${url}</a>`
  })

  // Restore original <a> tags
  const finalContent = contentWithNewATags.replace(
    /__LINK_PLACEHOLDER_\d+__/g,
    (placeholder) => placeholders[placeholder]
  )

  return finalContent
}

function CreateArticleScreen({
  cluster,
  route,
}: StackScreenProps<AppNavigatorParams, 'CreateArticle'> & {
  cluster: Cluster
}) {
  const { t } = useTranslation('articles')
  const { state } = useLLMState()
  const [mode, setMode] = useState<'manual' | 'ai'>(
    state.model.length > 0 ? 'ai' : 'manual'
  )
  const llmState = useLLMState()
  const { keyboardDisplayed } = useKeyboard()

  // Init Load
  useEffect(() => {
    const { model } = route.params || {}
    const modelArray = model ? [model] : [DEFAULT_MODEL]
    llmState.setState({
      model: modelArray,
      prompt: getDefaultPrompt(modelArray[0], {
        prompt: cluster.topic || '',
        language: i18n.language,
      }),
    })
    if (!!model) {
      setMode('ai')
    }
  }, [])

  const tabs = [
    {
      label: t('manual'),
      value: 'manual',
      icon: IconManualPerspective,
    },
    { label: t('ai'), value: 'ai', icon: IconAIPerspective },
  ]

  const styles = StyleSheet.create({
    content: {
      flex: 1,
      flexDirection: 'column',
      width: isDesktopBrowser() ? '50%' : '95%',
    },
  })

  useEffect(() => {
    if (!state.opinion) {
      return
    }
    setMode('manual')
    // For reinitializing the editor to load the LLm content
  }, [state.opinion])

  const initialValues = useMemo(() => {
    return {
      title: route.params?.title,
      content: route.params?.content,
    }
  }, [route.params])
  return (
    <ScreenWrapper
      withScrollView={true}
      contentContainerStyle={{
        flex: 1,
        alignItems: 'center',
      }}
    >
      <Text style={{ marginTop: 10, textAlign: 'center' }}>
        <Text style={{ fontWeight: 'bold' }}>{t('topic')}</Text> {cluster.topic}
      </Text>

      <View style={styles.content}>
        {!keyboardDisplayed && (
          <MelddTabs
            items={tabs}
            selected={mode}
            onSelect={(value) => {
              setMode(value as 'manual' | 'ai')
            }}
          />
        )}

        {mode === 'manual' ? (
          <CreateManualArticle
            llmContent={state.opinion}
            cluster={cluster}
            onEditPrompt={() => {
              setMode('ai')
            }}
            initialValues={initialValues}
          />
        ) : null}
        {mode === 'ai' ? <CreateAIArticle cluster={cluster} /> : null}
      </View>
    </ScreenWrapper>
  )
}

function CreateAIArticle({ cluster }: { cluster: Cluster }) {
  const { t } = useTranslation('articles')
  const llm = useLLMState()

  const styles = StyleSheet.create({
    textArea: {
      backgroundColor: '#f5f2f7',
      padding: 15,
      marginBottom: 20,
      flex: 1,
      paddingBottom: 20,
      textAlignVertical: 'top',
      minHeight: 350, // This makes the button fix at the bottom, but if the screen is too short this will force the scroll
    },
    picker: {
      backgroundColor: '#f5f2f7',
      marginBottom: 20,
    },
  })

  const setLLM = (callback: (prev: string[]) => string[]) => {
    const updatedLLMs = callback(llm.state.model)

    const isUsingDefaultPrompt =
      llm.state.prompt ===
      getDefaultPrompt(llm.state.model[0], {
        prompt: cluster.topic || '',
        language: i18n.language,
      })

    llm.setState({
      model: updatedLLMs,
    })

    // Update the prompt, unless the user has already set a custom prompt
    if (isUsingDefaultPrompt) {
      llm.setState({
        prompt: getDefaultPrompt(updatedLLMs[0], {
          prompt: cluster.topic || '',
          language: i18n.language,
        }),
      })
    }
  }
  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <View>
        <Row justifyContent="space-between" alignItems="center" flex={1}>
          <View style={{ flex: 1 }}>
            <LLMList llmModels={llm.state.model} setLlmModels={setLLM} />
          </View>
          <MelddButton
            onPress={() => llm.generateOpinion(cluster)}
            isDisabled={llm.state.loading}
            isLoading={llm.state.loading}
            color={Theme.color.primary.main}
            textColor="white"
            title={t('generate')}
            fontSize={15}
            style={{ marginHorizontal: 10, marginTop: 10 }}
          />
        </Row>
      </View>
      <Text variant="bodySmall" style={{ marginVertical: 10 }}>
        {t('prompt-placeholder')}
      </Text>
      <TextInput
        multiline
        numberOfLines={4}
        style={styles.textArea}
        placeholder={t('prompt-placeholder')}
        value={llm.state.prompt}
        onChangeText={(prompt) => llm.setState({ prompt })}
      />
    </View>
  )
}

function CreateManualArticle({
  cluster,
  llmContent,
  onEditPrompt,
  initialValues,
}: {
  cluster: Cluster
  llmContent?: string
  onEditPrompt: () => void
  initialValues: {
    title?: string
    content?: string
  }
}) {
  const { t } = useTranslation('articles')
  const { t: tError } = useTranslation('errors')

  const navigation =
    useNavigation<StackScreenProps<AppNavigatorParams>['navigation']>()
  const [title, setTitle] = useState('')
  const editorRef = useRef<ArticleEditorRef>(null)
  const llmState = useLLMState()
  const { keyboardDisplayed } = useKeyboard()

  const [doCreateArticle, { loading }] = useCreateArticleMutation({
    update: (cache, result) => {
      const { data } = result
      if (data) {
        cache.modify({
          id: `Cluster:${cluster.id}`,
          fields: {
            articles: (currentArticles: readonly any[]) => {
              const newArticleRef = cache.writeFragment({
                data: data.createArticle,
                fragment: ArticleDetailFragmentDoc,
              })
              return [...currentArticles, newArticleRef]
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (!initialValues) {
      return
    }
    setTitle(initialValues.title || '')
    editorRef.current?.setEditorContent(initialValues.content || '')
  }, [initialValues])

  useEffect(() => {
    if (!llmContent) {
      return
    }
    editorRef.current?.setEditorContent(llmContent)
  }, [llmContent])

  const handleCreateArticle = async (
    originalContent: string,
    originalText: string
  ) => {
    if (!originalText.trim()) {
      return Toast.show(t("The content can't be empty"), MessageType.warn)
    }
    const processors = [trimProcessor, convertLinksProcessor]
    const finalContent = processors.reduce(
      (acc, processor) => processor(acc),
      originalContent
    )

    const _title = !title.trim() ? originalText.trim().slice(0, 30) : title

    const input: CreateArticleInput = {
      originalContent: finalContent,
      title: _title,
      clusterId: cluster.id,
      isAutoTitle: !title.trim(),
    }
    try {
      const result = await doCreateArticle({ variables: { input } })
      Toast.show(t('Your article has been created'), MessageType.info)

      navigation.replace('Perspective', {
        articleId: result.data?.createArticle?.id || '',
      })
    } catch (e) {
      if (e instanceof ApolloError) {
        const errorCode = getApolloErrorCode(e)
        if (errorCode === '409') {
          return Toast.show(
            input.isAutoTitle
              ? tError('create-article-autotitle-conflict')
              : tError('create-article-conflict'),
            MessageType.error
          )
        }
      }
      Toast.show((e as Error).message, MessageType.error)

      console.error(e)
      Sentry.captureException(e)
    }
  }

  const onCreateArticle = () => {
    editorRef.current?.requestContent()
  }

  function toHomepage() {
    navigation.pop()
  }

  const styles = StyleSheet.create({
    container: {
      paddingVertical: 10,
      flex: 1,
    },
    title: {
      height: 32,
      justifyContent: 'flex-start',
      backgroundColor: '#f5f2f7',
      minHeight: 30,
      paddingHorizontal: 15,
      flex: 1,
      width: '100%',
      marginBottom: 10,
    },
    button: {
      marginHorizontal: 10,
      marginTop: 10,
    },
    editor: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 350, // This makes the button fix at the bottom, but if the screen is too short this will force the scroll
    },
  })

  return (
    <View style={styles.container}>
      {!!llmContent && (
        <Row justifyContent="center" style={{ paddingBottom: 12 }}>
          <MelddButton
            onPress={() => llmState.generateOpinion(cluster)}
            isDisabled={llmState.state.loading}
            isLoading={llmState.state.loading}
            color="grey"
            title={t('regenerate-ai')}
            textColor="white"
            fontSize={15}
            style={styles.button}
          />
          <MelddButton
            onPress={() => onEditPrompt()}
            color={Theme.color.primary.main}
            textColor="white"
            title={t('edit-prompt')}
            fontSize={15}
            style={styles.button}
          />
        </Row>
      )}
      <View>
        <TextInput
          editable={true}
          style={[styles.title]}
          underlineColorAndroid="transparent"
          placeholder={t('Automatic title')}
          placeholderTextColor="grey"
          onChangeText={(text) => setTitle(text)}
          value={title}
        />
      </View>
      <View style={styles.editor}>
        <ArticleEditor
          ref={editorRef}
          editorMode={'edit'}
          onHtmlContent={handleCreateArticle}
        />
      </View>
      {!keyboardDisplayed && (
        <Row justifyContent="center" style={{ height: 50 }}>
          <MelddButton
            onPress={toHomepage}
            isDisabled={loading}
            color="grey"
            title={t('Cancel')}
            textColor="white"
            fontSize={15}
            style={styles.button}
          />
          <MelddButton
            onPress={onCreateArticle}
            isDisabled={loading}
            isLoading={loading}
            color={Theme.color.primary.main}
            textColor="white"
            title={t('Publish')}
            fontSize={15}
            style={styles.button}
          />
        </Row>
      )}
    </View>
  )
}

export default function ({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'CreateArticle'>) {
  const { clusterId } = route.params
  const { t } = useTranslation('articles')
  const { data: clusterData } = useClusterByIdQuery({
    variables: { id: clusterId },
  })

  const cluster = useMemo(
    () => clusterData?.clusterById as Cluster,
    [clusterData]
  )

  useEffect(() => {
    if (!clusterId) {
      Toast.show(
        t('No cluster found. Please refresh the app.'),
        MessageType.error
      )
      navigation.pop()
    }
  }, [])

  if (!cluster) return <LoadingFullScreen />

  return (
    <LLMProvider>
      <CreateArticleScreen
        navigation={navigation}
        route={route}
        cluster={cluster}
      />
    </LLMProvider>
  )
}
