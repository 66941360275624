import React, { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-native-root-toast'
import { MessageType } from '../../utils/message-type'
import { Cluster } from '../ClusterList/types'
import { useGenerateOpinionMutation } from '../../generated/graphql'
import * as Sentry from '@sentry/react-native'

type LLMState = {
  model: string[]
  prompt: string
  opinion: string
  loading: boolean
}
interface LLMContextType {
  state: LLMState
  setState: (state: Partial<LLMState>) => void
  generateOpinion: (cluster: Cluster) => Promise<void>
}

const LLMContext = createContext<LLMContextType | undefined>(undefined)

const DefaultPrompt = (prompt: string, language: string) =>
  `${prompt}. the language of the response should be ${language}. Format your response using only basic HTML tags for structure (like <h1>, <p>, <strong>). Do not include <!DOCTYPE>, <html>, <head>, <body> or any metadata tags.`

const DEFAULT_PROMPTS_BY_MODEL = {
  '4.0Ultra': DefaultPrompt,
  '4.0Pro': DefaultPrompt,
  'deepseek-chat': DefaultPrompt,
  'deepseek-reasoner': DefaultPrompt,
}

export const DEFAULT_MODEL = 'deepseek-chat'

export const getDefaultPrompt = (
  model: string,
  options: {
    prompt: string
    language: string
  }
) => {
  if (
    !model ||
    !DEFAULT_PROMPTS_BY_MODEL[model as keyof typeof DEFAULT_PROMPTS_BY_MODEL]
  )
    return DefaultPrompt(options.prompt, options.language)

  return DEFAULT_PROMPTS_BY_MODEL[
    model as keyof typeof DEFAULT_PROMPTS_BY_MODEL
  ](options.prompt, options.language)
}

export function LLMProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation('articles')
  const [state, _setState] = useState({
    model: [] as string[],
    prompt: '',
    opinion: '',
    loading: false,
  })
  const [doGenerateOpinion] = useGenerateOpinionMutation()

  const setState = (state: Partial<LLMState>) => {
    _setState((prevState) => ({ ...prevState, ...state }))
  }

  const generateOpinion = async (cluster: Cluster) => {
    if (state.loading) return

    if (!state.prompt.trim()) {
      return Toast.show(t(`The content can't be empty`), MessageType.warn)
    }
    if (state.model.length === 0) {
      return Toast.show(t(`model-required`), MessageType.warn)
    }
    // Temporal Restriction
    if (state.model.length > 1) {
      return Toast.show(t(`only-one-model`), MessageType.warn)
    }
    setState({ loading: true })

    try {
      const opinionResult = await doGenerateOpinion({
        variables: {
          clusterId: cluster.id,
          prompt: state.prompt,
          model: state.model[0],
        },
      })

      if (!opinionResult.data?.generateOpinion) {
        return Toast.show(t('no-opinion-generated'))
      }
      setState({ opinion: opinionResult.data?.generateOpinion, loading: false })
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
      console.error(e)
      Sentry.captureException(e)
    } finally {
      setState({ loading: false })
    }
  }
  return (
    <LLMContext.Provider
      value={{
        state,
        setState,
        generateOpinion,
      }}
    >
      {children}
    </LLMContext.Provider>
  )
}

export function useLLMState() {
  const context = useContext(LLMContext)
  if (context === undefined) {
    throw new Error('useLLMState must be used within an LLMProvider')
  }
  return context
}
