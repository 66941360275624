import * as React from 'react'
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  DEFAULT_FEATURES,
  featureLoader,
  Features,
  FEATURES_KEY,
} from '../utils/features'

interface FeatureProviderProps {
  children: React.ReactNode
}

interface FeaturesProviderShape extends Features {
  setShowEditorLogs: (val: boolean) => void
  setOwnerBumps: (val: boolean) => void
  setNotifications: (val: boolean) => void
  setNotificationsSound: (val: boolean) => void
  setDeveloperMode: (val: boolean) => void
  setTheme: (val: 'light' | 'dark') => void
}

const FeatureProviderContext = createContext<FeaturesProviderShape>({
  showEditorLogs: false,
  setShowEditorLogs: (val: boolean) => {},
  ownerBumps: false,
  setOwnerBumps: (val: boolean) => {},
  notifications: false,
  setNotifications: (val: boolean) => {},
  notificationsSound: false,
  setNotificationsSound: (val: boolean) => {},
  developerMode: false,
  setDeveloperMode: (val: boolean) => {},
  theme: 'light',
  setTheme: (val: 'light' | 'dark') => {},
})

type Reset = { type: 'reset'; value: Features }
type SetEditorLogs = { type: 'set-editor-logs'; value: boolean }
type SetDeveloperMode = { type: 'set-developer-mode'; value: boolean }
type SetOwnerBumps = { type: 'set-owner-bumps'; value: boolean }
type SetNotifications = { type: 'set-notifications'; value: boolean }
type SetNotificationsSound = { type: 'set-notifications-sound'; value: boolean }
type SetTheme = { type: 'set-theme'; value: 'light' | 'dark' }
type Action =
  | Reset
  | SetEditorLogs
  | SetOwnerBumps
  | SetNotifications
  | SetNotificationsSound
  | SetDeveloperMode
  | SetTheme

const reducer = (state: Features, action: Action) => {
  switch (action.type) {
    case 'set-editor-logs':
      return { ...state, showEditorLogs: action.value }
    case 'set-owner-bumps':
      return { ...state, ownerBumps: action.value }
    case 'set-notifications':
      return { ...state, notifications: action.value }
    case 'set-notifications-sound':
      return { ...state, notificationsSound: action.value }
    case 'set-developer-mode':
      return { ...state, developerMode: action.value }
    case 'reset':
      return action.value
    case 'set-theme':
      return { ...state, theme: action.value }
  }
}
export default function FeatureProvider(props: FeatureProviderProps) {
  const [featuresState, dispatch] = useReducer(reducer, DEFAULT_FEATURES)
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {
    featureLoader.then((value) => {
      dispatch({ type: 'reset', value })
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    if (!loading) {
      const s = JSON.stringify(featuresState)
      AsyncStorage.setItem(FEATURES_KEY, s)
      console.log('FeatureProvider:saving features', s)
    }
  }, [featuresState, loading])
  const value: FeaturesProviderShape = useMemo(() => {
    return {
      ...featuresState,
      setShowEditorLogs: (value: boolean) =>
        dispatch({ type: 'set-editor-logs', value }),
      setOwnerBumps: (value: boolean) =>
        dispatch({ type: 'set-owner-bumps', value }),
      setNotifications: (value: boolean) =>
        dispatch({ type: 'set-notifications', value }),
      setNotificationsSound: (value: boolean) =>
        dispatch({ type: 'set-notifications-sound', value }),
      setDeveloperMode: (value: boolean) =>
        dispatch({ type: 'set-developer-mode', value }),
      setTheme: (value: 'light' | 'dark') =>
        dispatch({ type: 'set-theme', value }),
    }
  }, [featuresState])

  return (
    <FeatureProviderContext.Provider value={value} children={props.children} />
  )
}

export const useFeatures = () => useContext(FeatureProviderContext)
