import { useCallback, useRef, useState } from 'react'
import { ActivityIndicator, Animated, StyleProp, ViewStyle } from 'react-native'
import { Icon, IconButton, Searchbar, SearchbarProps } from 'react-native-paper'
import { IconSearch } from '../../utils/meldd-icons'
import { createStyle } from '../../contexts/GlobalStylesProvider'
import { createDebounce } from '../../utils'

interface CollapsibleSearchBarProps extends Omit<SearchbarProps, 'style'> {
  collapsed?: boolean
  animationWidth?: number
  onSearch?: (text: string) => Promise<void>
  style?: {
    button?: StyleProp<ViewStyle>
    searchBar?: StyleProp<ViewStyle>
  }
}

export const MelddSearchBar = ({
  collapsed = false,
  animationWidth = 300,
  value,
  onChangeText,
  onSearch,
  placeholder,
  style,
  icon,
  ...props
}: CollapsibleSearchBarProps) => {
  const [isExpanded, setIsExpanded] = useState(!collapsed)
  const [isAnimating, setIsAnimating] = useState(false)
  const searchBarRef = useRef<any>(null)
  const expandAnim = useRef(new Animated.Value(collapsed ? 0 : 1)).current
  const debouncer = useCallback(createDebounce(500), [])
  const [isSearching, setIsSearching] = useState(false)

  const styles = useStyle()
  const toggleSearch = () => {
    const toValue = isExpanded ? 0 : 1
    setIsAnimating(true)
    Animated.timing(expandAnim, {
      toValue: toValue,
      duration: 200,
      useNativeDriver: false,
    }).start(() => {
      if (!isExpanded) {
        // Focus the search bar when expanded
        searchBarRef.current?.focus()
      }
    })
    setTimeout(() => {
      setIsExpanded(!isExpanded)
      setIsAnimating(false)
    }, 210)
  }

  const containerWidth = expandAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [40, animationWidth],
  })

  const handleSearch = useCallback(
    (text: string) => {
      onChangeText?.(text)
      if (onSearch) {
        setIsSearching(true)
        debouncer(async () => {
          await onSearch(text)
          setIsSearching(false)
        })
      }
    },
    [onSearch, onChangeText]
  )

  if (!isExpanded && !isAnimating) {
    return (
      <IconButton
        mode="contained-tonal"
        onPress={toggleSearch}
        icon={icon ?? IconSearch}
        size={24}
        style={[styles.roundButton, style?.button]}
      />
    )
  }

  return (
    <Animated.View
      style={[
        styles.container,
        isExpanded ? { flex: 1 } : { width: containerWidth },
      ]}
    >
      <Searchbar
        {...props}
        icon={
          isSearching
            ? (props) => (
                <ActivityIndicator size={props.size} color={props.color} />
              )
            : (props) => (
                <Icon source={icon ?? IconSearch} color={'#888'} size={24} />
              )
        }
        onIconPress={() => (isSearching || !collapsed ? null : toggleSearch())}
        ref={searchBarRef}
        value={value}
        onBlur={() => {
          if (collapsed) {
            !value && toggleSearch()
          }
        }}
        placeholderTextColor={'#888'}
        onChangeText={handleSearch}
        placeholder={placeholder}
        inputStyle={styles.input}
        style={[
          styles.searchBar,
          style?.searchBar,
          // Italic Placeholder
          value.length === 0 && {
            fontStyle: 'italic',
          },
        ]}
      />
    </Animated.View>
  )
}

const useStyle = createStyle(({ theme }) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 40,
  },
  searchBar: {
    height: 40,
    flex: 1,
  },
  input: {
    minHeight: 0,
  },
  iconContainer: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundButton: {
    backgroundColor: theme.colors.inputContainer,
  },
}))
