import * as React from 'react'
import { Appbar, IconButton } from 'react-native-paper'
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack'
import { AppNavigation, AppNavigatorParams } from '../../../navigation/types'
import { View } from 'react-native'
import { useTheme } from '../../../hooks/use-theme'
import { IconClose, VisibilityIcon } from '../../../utils/meldd-icons'
import { useHeaderButton } from '../../../contexts/HeaderButtonProvider'
import { RemainingTimeIndicator } from './RemainingTimeIndicator'
import { Article, Contribution } from '../types'
import { Env } from '../../../env'
import { useTranslation } from 'react-i18next'
import { createStyle } from '../../../contexts/GlobalStylesProvider'
import PerspectiveScreenBarMenu from '../../../components/PerspectiveScreenBarMenu'
import { TouchableIconWithToolTip } from '../../../utils/meldd-tooltip'
import Row from '../../../components/containers/Row'
import { useNavigation } from '@react-navigation/native'
import { ContributionCount } from './ContributionCount'
export interface PerspectiveNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export const EditingPerspectiveNavigationBar = ({
  reservation,
}: {
  reservation: Contribution
}) => {
  const { handler: cancelHandler, disabled: cancelDisabled } =
    useHeaderButton('cancelContribution')
  const theme = useTheme()
  const styles = useStyle()

  return (
    <Appbar.Header
      style={[
        styles.container,
        { backgroundColor: theme.palettes.primary[96]! },
      ]}
    >
      {cancelHandler && (
        <IconButton
          disabled={cancelDisabled}
          mode={'outlined'}
          icon={IconClose}
          style={{ margin: 0 }}
          onPress={cancelHandler}
        />
      )}
      <RemainingTimeIndicator
        maxDuration={Env.MAX_RESERVATION_DURATION}
        validUntil={new Date(reservation.reservedUntil).getTime()}
      />
    </Appbar.Header>
  )
}

const VisibilityButton = () => {
  const { t } = useTranslation('menuItems')

  const { meta: detailsVisible, setMeta: setDetailsVisible } =
    useHeaderButton<boolean>('visibleButton', false)
  return (
    <TouchableIconWithToolTip
      mode="text"
      icon={<VisibilityIcon toggled={detailsVisible} size={24} />}
      tooltip={t('see-details')}
      onPress={() => {
        setDetailsVisible(!detailsVisible)
      }}
    />
  )
}

const BackButton = () => {
  const { handler: backButton } = useHeaderButton('navigation')
  const navigation = useNavigation<AppNavigation>()

  const goBack = React.useCallback(() => {
    return backButton ? backButton() : navigation.goBack()
  }, [backButton, navigation])

  return (
    <Appbar.BackAction onPress={() => goBack()} style={{ marginLeft: -10 }} />
  )
}

export function PerspectiveNavigationBar({
  navigation,
}: PerspectiveNavigationBarProps) {
  const styles = useStyle()
  const { meta: reservation } =
    useHeaderButton<Contribution>('saveContribution')
  const { meta: perspective } = useHeaderButton<Article>('perspective')
  const { meta: visible } = useHeaderButton<boolean>('visibleButton')
  const { meta: eagleView } = useHeaderButton<boolean>('eagleView')
  const isEditing = !!reservation

  if (isEditing) {
    return <EditingPerspectiveNavigationBar reservation={reservation} />
  }
  return (
    <Appbar.Header style={styles.container}>
      <BackButton />

      <Row
        style={{ marginBottom: 2 }}
        flex={1}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {visible && perspective && (
          <ContributionCount
            perspectiveId={perspective.id}
            contributions={perspective.contributions}
            visible={false}
          />
        )}
      </Row>

      <Row justifyContent={'flex-end'} alignItems={'center'}>
        <View style={{ marginBottom: 2 }}>
          <VisibilityButton />
        </View>

        <PerspectiveScreenBarMenu
          perspective={perspective}
          navigation={navigation}
        />
      </Row>
    </Appbar.Header>
  )
}

const useStyle = createStyle(({ theme }) => ({
  container: {
    backgroundColor: theme.palettes.primary[96]!,
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
}))
