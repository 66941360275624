import { useCallback, useEffect, useState } from 'react'
import { Dimensions, Text } from 'react-native'
import { Chip, Menu, Searchbar } from 'react-native-paper'
import { useTheme } from '../hooks/use-theme'
import { useTranslation } from 'react-i18next'
interface DropdownItem {
  id: string | number
  label: string
  icon?: string
}

interface DropdownProps<T extends DropdownItem> {
  items: T[]
  selected?: T
  onSelect: (item: T) => void
  loading?: boolean
  placeholder?: string
  searchPlaceholder?: string
  icon?: string
  showSearch?: boolean
  chipMode?: 'outlined' | 'flat'
  style?: object
  disabled?: boolean
  onSearch?: (query: string) => void | Promise<void>
  useLocalFilter?: boolean
  searchDebounceMs?: number
}

export default function Dropdown<T extends DropdownItem>({
  items,
  selected,
  onSelect,
  loading = false,
  placeholder = '',
  searchPlaceholder = '',
  icon,
  showSearch = true,
  chipMode = 'outlined',
  style,
  disabled = false,
  onSearch,
  useLocalFilter = true,
  searchDebounceMs = 300,
}: DropdownProps<T>) {
  const windowWidth = Dimensions.get('window').width
  const theme = useTheme()
  const [searchQuery, setSearchQuery] = useState('')
  const [visible, setVisible] = useState(false)
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  )
  const { t } = useTranslation('common')

  const handleSearch = useCallback(
    (query: string) => {
      setSearchQuery(query)

      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      if (onSearch) {
        const timeout = setTimeout(() => {
          onSearch(query)
        }, searchDebounceMs)
        setSearchTimeout(timeout)
      }
    },
    [onSearch, searchDebounceMs, searchTimeout]
  )

  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
    }
  }, [searchTimeout])

  const onSelection = useCallback(
    (item: T) => {
      setVisible(false)
      onSelect(item)
    },
    [onSelect]
  )

  const filteredItems = useLocalFilter
    ? items.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : items

  return (
    <Menu
      visible={visible}
      anchorPosition={'bottom'}
      style={{
        flex: 1,
        marginTop: theme.spacing(1),
        ...style,
      }}
      onDismiss={() => {
        if (!isSearchFocused) {
          setVisible(false)
        }
      }}
      anchor={
        <Chip
          icon={icon}
          mode={chipMode}
          closeIcon={visible ? 'menu-up' : 'menu-down'}
          onPress={() => !disabled && setVisible((prev) => !prev)}
          onClose={() => !disabled && setVisible((prev) => !prev)}
          disabled={disabled}
        >
          <Text>
            {loading
              ? ''
              : selected?.label || placeholder || t('select-placeholder')}
          </Text>
        </Chip>
      }
    >
      {showSearch && (
        <Searchbar
          placeholder={searchPlaceholder || t('search-placeholder')}
          onChangeText={handleSearch}
          value={searchQuery}
          onBlur={() => setIsSearchFocused(false)}
          onFocus={() => setIsSearchFocused(true)}
        />
      )}
      {filteredItems.map((item) => (
        <Menu.Item
          key={item.id}
          style={{ width: '100%', maxWidth: '100%' }}
          leadingIcon={item.icon}
          onPress={() => onSelection(item)}
          title={item.label}
        />
      ))}
    </Menu>
  )
}
