import * as Clipboard from 'expo-clipboard'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import {
  Button,
  Dialog,
  IconButton,
  Menu,
  Portal,
  Text,
} from 'react-native-paper'
import Toast from 'react-native-root-toast'

interface ShareModalProps {
  url: string
}

interface ModalProps {
  setOpen: (open: boolean) => void
  open: boolean
}

const CopyLinkButton = (props: ShareModalProps) => {
  const { t } = useTranslation('common')
  const copyToClipboard = async () => {
    Clipboard.setStringAsync(props.url)
    Toast.show(t('Success'))
  }

  return <IconButton mode="outlined" icon={'link'} onPress={copyToClipboard} />
}

const ShareDialogue = ({
  open,
  setOpen,
  url,
}: ShareModalProps & ModalProps) => {
  const { t } = useTranslation('menuItems')
  return (
    <Portal>
      <Dialog
        visible={open}
        onDismiss={() => setOpen(false)}
        dismissable={true}
        dismissableBackButton={true}
      >
        <View style={styles.modalWrapper}>
          <Dialog.Title>
            <Text variant="bodyMedium">{t('Share')}</Text>
          </Dialog.Title>
          <Dialog.Content>
            <View style={styles.row}>
              <CopyLinkButton url={url} />
            </View>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setOpen(false)}>{t('Cancel')}</Button>
          </Dialog.Actions>
        </View>
      </Dialog>
    </Portal>
  )
}
export const ShareModalMenu = (props: ShareModalProps) => {
  const { t } = useTranslation('menuItems')
  const [open, setOpen] = useState(false)

  return (
    <>
      <Menu.Item
        leadingIcon="share"
        onPress={() => {
          setOpen(true)
        }}
        title={t('Share')}
      />
      <ShareDialogue open={open} setOpen={setOpen} url={props.url} />
    </>
  )
}

export const ShareModal = (props: ShareModalProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton size={20} icon="share" onPress={() => setOpen(true)} />
      <ShareDialogue open={open} setOpen={setOpen} url={props.url} />
    </>
  )
}

const styles = StyleSheet.create({
  title: { fontSize: 24, fontWeight: '600' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
  },
  modalWrapper: {
    borderRadius: 4,
    margin: 32,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
