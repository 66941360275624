import { useMemo, useRef, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import {
  Divider,
  Menu,
  PaperProvider,
  Text,
  useTheme,
} from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { MelddTaskTargetType } from '../../screens/tasks/task.types'
import colors from '../../constants/colors'
import {
  IconAssignedTask,
  IconAssignTask,
  IconCompleted,
  IconCreatedTask,
  IconM,
  IconOpenContribution,
} from '../../utils/meldd-icons'
import { CreateTaskModal, CreateTaskModalProps } from './CreateTaskModal'
import { useAuthContext } from '../../auth/auth-context'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../navigation/types'

type Props = {
  targetId: string
  targetType: MelddTaskTargetType
  preview: string
}
/**
 * M Button is a fix button on the bottom of the screen that opens a menu with different actions
 * Different pages may have different actions
 */

const MButtonTrigger = ({
  onPress,
  visible,
}: {
  onPress: (flag: boolean) => void
  visible: boolean
}) => {
  const theme = useTheme()
  const styles = useStyle()
  return (
    <Pressable
      onPress={() => onPress(!visible)}
      style={({ pressed }) => [
        {
          opacity: pressed ? 0.5 : 1,
        },
        styles.button,
      ]}
    >
      {!visible ? (
        <IconM color={theme.colors.primary} />
      ) : (
        <Text style={styles.text}>{'X'}</Text>
      )}
    </Pressable>
  )
}
const MButton = (props: Props) => {
  const { t } = useTranslation('menu')
  const createTaskModalRef = useRef<CreateTaskModalProps>(null)
  const [visible, setVisible] = useState(false)
  const styles = useStyle()
  const { authAction } = useAuthContext()
  const navigator = useNavigation<AppNavigation>()

  return (
    <>
      <CreateTaskModal ref={createTaskModalRef} />
      <View style={styles.container}>
        <PaperProvider>
          <Menu
            visible={visible}
            onDismiss={() => setVisible(false)}
            anchor={
              <MButtonTrigger
                onPress={(visible) => setVisible(visible)}
                visible={visible}
              />
            }
            style={styles.menu}
          >
            {/*
            TODO: Uncomment when we have the assigned task functionality
            <Menu.Item
              leadingIcon={IconAssignedTask}
              onPress={() => {}}
              title={t('assigned-task')}
              onPress={() => {
                authAction(navigator, () => {
                  navigator.navigate('MyTasks', { tab: 'assigned' })
                })
              }}
            /> */}
            <Menu.Item
              leadingIcon={IconCompleted}
              title={t('completed-tasks')}
              onPress={() => {
                authAction(navigator, () => {
                  navigator.navigate('MyTasks', { tab: 'completed' })
                })
              }}
            />
            <Menu.Item
              leadingIcon={IconCreatedTask}
              onPress={() => {
                authAction(navigator, () => {
                  navigator.navigate('MyTasks', { tab: 'pending' })
                })
              }}
              title={t('pending-tasks')}
            />
            <Divider />
            <Menu.Item
              leadingIcon={IconAssignTask}
              onPress={() => {
                authAction(navigator, () => {
                  createTaskModalRef.current?.open(
                    props.targetId,
                    props.targetType,
                    props.preview
                  )
                  setVisible(false)
                })
              }}
              title={t('create-task')}
            />
          </Menu>
        </PaperProvider>
      </View>
    </>
  )
}

export default MButton

const useStyle = () => {
  const theme = useTheme()
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          position: 'absolute',
          right: 32,
          bottom: 32,
          zIndex: 99,
        },
        menu: {
          position: 'absolute',
          top: -170,
          left: -122,
        },
        button: {
          backgroundColor: colors.screen,
          borderRadius: 12,
          width: 56,
          height: 56,
          justifyContent: 'center',
          alignItems: 'center',
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5, // for Android
        },
        text: {
          fontSize: 24,
          fontWeight: 'bold',
          color: theme.colors.primary,
        },
      }),
    [theme]
  )
}
