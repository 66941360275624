import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../navigation/types'
import { TargetType, TimeLineModel } from './../generated/graphql'

export function navigateToTarget({
  target,
  navigation,
}: {
  target: { type: TargetType; id: string }
  navigation: ReturnType<typeof useNavigation<AppNavigation>>
}) {
  if (target.type === TargetType.Article)
    return navigation.navigate('Perspective', {
      articleId: target.id,
    })

  if (target.type === TargetType.Node) {
    return navigation.navigate('ClusterList', {
      nodeId: target.id,
    })
  }

  if (target.type === TargetType.Cluster) {
    return navigation.navigate('ClusterCards', {
      clusterId: target.id,
    })
  }

  throw new Error('target must be used with a valid targetype')
}

export function navigateToTimelinePayload({
  timeline,
  navigation,
}: {
  timeline: TimeLineModel
  navigation: ReturnType<typeof useNavigation<AppNavigation>>
}) {
  const payload = timeline.payload

  if (payload?.articleId)
    return navigation.navigate('Perspective', {
      articleId: payload.articleId,
    })

  if (payload?.clusterId) {
    return navigation.navigate('ClusterCards', {
      clusterId: payload.clusterId,
    })
  }
  navigateToTarget({
    target: { type: timeline.target, id: timeline.targetId },
    navigation,
  })
}
