import { MD3TypescaleKey, Text } from 'react-native-paper'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { useTheme } from '../../hooks/use-theme'
import { VariantProp } from 'react-native-paper/lib/typescript/components/Typography/types'

interface HighlightTextProps {
  text: string
  searchTerm: string
  variant?: VariantProp<MD3TypescaleKey>
  style?: StyleProp<TextStyle>
  highlightStyle?: StyleProp<TextStyle>
}

export const HighlightText = ({
  text,
  variant = 'bodyMedium',
  searchTerm,
  style,
  highlightStyle,
}: HighlightTextProps) => {
  const styles = useStyles()

  if (!searchTerm) return <Text>{text}</Text>

  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'))

  return (
    <Text variant={variant}>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <Text key={index} style={[styles.highlight, highlightStyle]}>
            {part}
          </Text>
        ) : (
          <Text key={index} style={style}>
            {part}
          </Text>
        )
      )}
    </Text>
  )
}

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    highlight: {
      fontWeight: 'bold',
      backgroundColor: theme.colors.primaryContainer,
    },
  })
}
