import * as React from 'react'
import { useTheme } from '../../../hooks/use-theme'
import { useMemo } from 'react'
import { Article, Cluster } from '../types'
import { View, StyleSheet, Pressable } from 'react-native'
import { Text, List } from 'react-native-paper'
import { IconPerspective } from '../../../utils/meldd-icons'
import { ContributionsTypesCount } from '../../../components/ContributionsTypesCount'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'
import { VoteButton } from '../../../components/vote/VoteButton'
import { TutorialComponent } from '../../../components/Tutorial/TutorialComponent'

interface PerspectiveTileProps {
  cluster: Cluster
  perspective: Article
}

export function PerspectiveTile(props: PerspectiveTileProps) {
  const theme = useTheme()

  const { perspective, cluster } = props
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: theme.spacing(3),
          padding: theme.spacing(4),
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.surfaceVariant,
        },
        icon: {
          flex: 0,
          flexBasis: 'auto',
        },
        title: {
          flex: 1,
          rowGap: theme.spacing(2),
        },
        titleString: {},
        vote: {
          flex: 0,
          flexBasis: 'auto',
          backgroundColor: theme.colors.background,
          borderRadius: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      }),
    [theme]
  )
  const navigation = useNavigation<AppNavigation>()
  // const onPress = useCallback(() => navigation.navigate('PerspectiveCards', {clusterId: cluster.id, articleId: perspective.id}) , [perspective])
  const navigateToPerspective = () =>
    navigation.navigate('Perspective', {
      articleId: perspective.id,
    })

  return (
    <Pressable onPress={navigateToPerspective} style={styles.container}>
      <View style={styles.icon}>
        <IconPerspective
          size={22}
          color={theme.colors.primary}
          onPress={navigateToPerspective}
        />
      </View>
      <View style={styles.title}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text selectable={false} ellipsizeMode={'tail'} numberOfLines={3}>
            {perspective.title}
          </Text>
        </View>
        <ContributionsTypesCount
          perspectiveId={perspective.id}
          contributions={perspective.contributions}
        />
      </View>
      <VoteButton
        style={styles.vote}
        cluster={cluster}
        perspective={perspective}
        mode={'vertical'}
      />
    </Pressable>
  )
}
